<template>
    <nav class="navbar navbar-expand-lg navbar-dark" aria-label="Tenth navbar example" style="background-color:#1f238d;">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample08" aria-controls="navbarsExample08" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-md-center" id="navbarsExample08">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link">Instruksi TES 1 SUBTES 1</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link">TES 1 SUBTES 1</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link">Instruksi TES 1 SUBTES 2</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link">TES 1 SUBTES 2</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link">Instruksi TES 1 SUBTES 3</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page">TES 1 SUBTES 3</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link">Instruksi TES 1 SUBTES 4</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link">TES 1 SUBTES 4</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>