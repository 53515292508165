<template>
    <div style="background-color: #fff;">
        <div class="container">
            <main>
                <div class="row g-5" style="max-width:100%">
                    <div class="col-md-12 col-lg-12">
                        <br>
                        <br>
                        <br>
                        <div class="jumbotron mt-3">
                            <h1>SELESAI</h1>
                            <p class="lead">Terima Kasih Telah Berpartisipasi Dalam Psikotes Ini</p>
                            <a class="btn btn-lg btn-primary" @click="purge" role="button"><i class="fa fa-home"></i> Kembali Ke Halaman Depan</a>
                        </div>
                        <br>
                        <br>
                        <br>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
    export default{
        methods : {
            purge(){
                window.localStorage.removeItem('vuex');
                console.clear()
                location.reload()
            }
        },
        mounted(){
            
        }
    }
</script>