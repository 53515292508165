<template>
    <div style="background-color: #fff;">
        <div class="container">
            <main>
                <div class="row g-5" style="max-width:100%">
                    <div class="col-md-7 col-lg-8">
                        <h4 class="mb-3">TES 3</h4>
                        <form @submit.prevent="onSubmit">
                            <p style="justify-content: center;">Ada sembilan puluh ( 90 ) pasang, pilihlah satu dari setiap pasangan tersebut yang Saudara anggap paling dekat menggambarkan diri saudara.</p>
                            <p style="justify-content: center;">Bila tidak satupun dari sebuah pasangan pernyataan yang cocok, pilihlah yang Saudara anggap paling mendekati</p>
                            <p style="justify-content: center;">Isilah jawaban pada kolom yang sudah disediakan  pada setiap pernyataan yang Saudara pilih. </p>
                            <p style="justify-content: center;">Sebagai contoh : </p>
                            <table class="table table-bordered table-sm" border="1">
                                <tbody>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">1</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya adalah pekerja keras</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref1" value="A" v-model="val1"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya tidak mudah murung</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val1"></td>
                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <br>
                            <p style="justify-content: center;">Dalam hal ini Saudara memilih pernyataan " a ", karena pernyataan " a " merupakan gambaran diri Saudara. </p>
                            <p style="justify-content: center;">Tetapi jika pernyataan " b " lebih menggambarkan diri Saudara,maka lingkarilah tanda anak panah pada pernyataan " b ".</p>
                            <table class="table table-bordered table-sm" border="1">
                                <tbody>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">1</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya adalah pekerja keras</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref1" value="A" v-model="val2"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya tidak mudah murung</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val2"></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style="justify-content: center;">Kerjakan secepat mungkin dan pilihlah hanya satu pernyataan dari tiap pasang.</p>
                            <button class="w-100 btn btn-primary btn-lg" type="submit">Kirim</button>
                        </form>
                    </div>
                    <div class="col-md-5 col-lg-4">
                        <div class="position-sticky" style="top: 35vh;">
                            <div class="p-3 mb-4 bg-light rounded-3 text-center">
                                <div class="container-fluid py-3">
                                    <div class="card bg-danger text-white fw-bold" id="countdown59">
                                        <div class="values card-body"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    var { Timer } = require('easytimer.js');
    import Swal from 'sweetalert2'
    export default{
        data(){
            return {
                val1 : 'A',
                val2 : 'B'
            }
        },
        methods : {
            async onSubmit(){
                try {
                    var response = await axios.get(this.$store.getters.getServerURL+'par/papi-kostick-petunjuk/'+this.$store.getters.getLokasi)
                    if(response.data.data){
                        await axios.get(this.$store.getters.getServerURL+'page/papi-kostick-petunjuk/'+this.$store.getters.getId)
                        this.$store.dispatch('setPagePapiInst',true)
                        this.$router.replace({ name: 'papi_kostick' })
                    } else {
                        Swal.fire({
                            title: 'Peringatan',
                            text: response.data.error_desc,
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        })
                    }
                } catch(error) {
                    console.error(error)
                }
            }
        },
        mounted(){
            console.log('WELCOME PAPIKOSTICK INS')
            
            var timer = new Timer();
            timer.start({countdown: true, startValues: {seconds: 180}});

            $('#countdown59 .values').html(timer.getTimeValues().toString());

            timer.addEventListener('secondsUpdated', (e)=>{
                $('#countdown59 .values').html(timer.getTimeValues().toString());
            });

            timer.addEventListener('targetAchieved', (e)=>{
                this.onSubmit()
            });
        },
        unmounted(){
            console.log('GOOD BYE PAPIKOSTICK INS')
        }
    }
</script>