<template>
	<div style="background-color: #fff;">
        <div class="container-fluid">
            <main>
                <div class="row g-5" style="max-width:100%">
                    <div class="col-md-7 col-lg-8">
                    	<div class="p-3 mb-4 bg-light rounded-3">
							<div class="container-fluid py-5">
								<img src="/images/CFIT-1a.jpg" style="width: 100%;">
								<img src="/images/CFIT-1b.jpg" style="width: 100%;">
							</div>
						</div>
                    </div>
                    <div class="col-md-5 col-lg-4">
                    	<div class="position-sticky" style="top: 2rem;">
	                    	<div class="p-3 mb-4 bg-light rounded-3">
								<div class="container-fluid py-3">
									<h1 class="display-6 fw-bold">TES 1 - SUBTES 1</h1>
									<div class="card bg-danger text-white fw-bold" id="countdown2">
									    <div class="values card-body"></div>
									</div>
									<form @submit.prevent="onSubmit">
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>1.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio11" value="a" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio12" value="b" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio13" value="c" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio14" value="d" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio15" value="e" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio16" value="f" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>2.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio21" value="a" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio22" value="b" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio23" value="c" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio24" value="d" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio25" value="e" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio26" value="f" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>3.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio31" value="a" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio32" value="b" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio33" value="c" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio34" value="d" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio35" value="e" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio36" value="f" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>4.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions4" id="inlineRadio41" value="a" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions4" id="inlineRadio42" value="b" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions4" id="inlineRadio43" value="c" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions4" id="inlineRadio44" value="d" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions4" id="inlineRadio45" value="e" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions4" id="inlineRadio46" value="f" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>5.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions5" id="inlineRadio51" value="a" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions5" id="inlineRadio52" value="b" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions5" id="inlineRadio53" value="c" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions5" id="inlineRadio54" value="d" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions5" id="inlineRadio55" value="e" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions5" id="inlineRadio56" value="f" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>6.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions6" id="inlineRadio61" value="a" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions6" id="inlineRadio62" value="b" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions6" id="inlineRadio63" value="c" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions6" id="inlineRadio64" value="d" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions6" id="inlineRadio65" value="e" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions6" id="inlineRadio66" value="f" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>7.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions7" id="inlineRadio71" value="a" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions7" id="inlineRadio72" value="b" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions7" id="inlineRadio73" value="c" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions7" id="inlineRadio74" value="d" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions7" id="inlineRadio75" value="e" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions7" id="inlineRadio76" value="f" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>8.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions8" id="inlineRadio81" value="a" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions8" id="inlineRadio82" value="b" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions8" id="inlineRadio83" value="c" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions8" id="inlineRadio84" value="d" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions8" id="inlineRadio85" value="e" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions8" id="inlineRadio86" value="f" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>9.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions9" value="a" id="inlineRadio91" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions9" value="b" id="inlineRadio92" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions9" value="c" id="inlineRadio93" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions9" value="d" id="inlineRadio94" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions9" value="e" id="inlineRadio95" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions9" value="f" id="inlineRadio96" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>10.</b>&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions10" id="inlineRadio101" value="a" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions10" id="inlineRadio102" value="b" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions10" id="inlineRadio103" value="c" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions10" id="inlineRadio104" value="d" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions10" id="inlineRadio105" value="e" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions10" id="inlineRadio106" value="f" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>11.</b>&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions11" id="inlineRadio111" value="a" :disabled="disabled"  v-model="cfit11">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions11" id="inlineRadio112" value="b" :disabled="disabled"  v-model="cfit11">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions11" id="inlineRadio113" value="c" :disabled="disabled"  v-model="cfit11">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions11" id="inlineRadio114" value="d" :disabled="disabled"  v-model="cfit11">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions11" id="inlineRadio115" value="e" :disabled="disabled"  v-model="cfit11">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions11" id="inlineRadio116" value="f" :disabled="disabled"  v-model="cfit11">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>12.</b>&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions12" id="inlineRadio121" value="a" :disabled="disabled"  v-model="cfit12">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions12" id="inlineRadio122" value="b" :disabled="disabled"  v-model="cfit12">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions12" id="inlineRadio123" value="c" :disabled="disabled"  v-model="cfit12">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions12" id="inlineRadio124" value="d" :disabled="disabled"  v-model="cfit12">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions12" id="inlineRadio125" value="e" :disabled="disabled"  v-model="cfit12">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions12" id="inlineRadio126" value="f" :disabled="disabled"  v-model="cfit12">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>13.</b>&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions13" id="inlineRadio131" value="a" :disabled="disabled"  v-model="cfit13">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions13" id="inlineRadio132" value="b" :disabled="disabled"  v-model="cfit13">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions13" id="inlineRadio133" value="c" :disabled="disabled"  v-model="cfit13">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions13" id="inlineRadio134" value="d" :disabled="disabled"  v-model="cfit13">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions13" id="inlineRadio135" value="e" :disabled="disabled"  v-model="cfit13">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions13" id="inlineRadio136" value="f" :disabled="disabled"  v-model="cfit13">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<button class="w-100 btn btn-primary btn-lg" type="submit" v-if="btn_submit">Kirim</button>
									</form>
								</div>
							</div>
						</div>
                    </div>
                </div>
			</main>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	var { Timer } = require('easytimer.js');
	import Swal from 'sweetalert2'

	export default{
		data(){
			return {
				disabled : false,
				cfit1 : null,
				cfit2 : null,
				cfit3 : null,
				cfit4 : null,
				cfit5 : null,
				cfit6 : null,
				cfit7 : null,
				cfit8 : null,
				cfit9 : null,
				cfit10 : null,
				cfit11 : null,
				cfit12 : null,
				cfit13 : null,
				btn_submit : this.$store.getters.getDebug
			}
		},
		methods : {
			async onSubmit(){
				try {
					let obj = {
						method  : 'post',
						url     : this.$store.getters.getServerURL+'cfit/1',
						data    : {
							id : this.$store.getters.getId,
							cfit1 : this.cfit1,
							cfit2 : this.cfit2,
							cfit3 : this.cfit3,
							cfit4 : this.cfit4,
							cfit5 : this.cfit5,
							cfit6 : this.cfit6,
							cfit7 : this.cfit7,
							cfit8 : this.cfit8,
							cfit9 : this.cfit9,
							cfit10 : this.cfit10,
							cfit11 : this.cfit11,
							cfit12 : this.cfit12,
							cfit13 : this.cfit13
						}
					}
					console.log('REQ :',obj)
					var response = await axios(obj)
					console.log('RES :',response.data)
					if(response.data.data){
						this.$store.dispatch('setPageCfitSoal1',response.data.data[0].id)
						this.$router.replace({ name: 'cfitinst2' })
					}
				} catch(error) {
					console.error(error)
					Swal.fire({
						title: 'Peringatan',
						text: 'Gagal Kirim Data. Tombol "Kirim" Ditampilkan. Coba Lagi dengan Menekan tombol "Kirim"',
						icon: 'warning',
						confirmButtonText: 'OK'
					})
					this.btn_submit = true
					this.disabled = true
				}
			}
		},
		mounted(){
			console.log('WELCOME CFIT TEST 1')
			var timer = new Timer();
			timer.start({countdown: true, startValues: {seconds: this.$store.getters.getCFIT1Interval}});

			$('#countdown2 .values').html(timer.getTimeValues().toString());

			timer.addEventListener('secondsUpdated', (e)=>{
				$('#countdown2 .values').html(timer.getTimeValues().toString());
			});

			timer.addEventListener('targetAchieved', (e)=>{
				if(!this.$store.getters.getDebug) this.onSubmit()
			});
		},
		unmounted(){
			console.log('GOOD BYE CFIT TEST 1')
		}
	}
</script>