<template>
    <div style="background-color: #fff;">
        <div class="container">
            <main>
                <div class="row g-5" style="max-width:100%">
                    <div class="col-md-7 col-lg-8">
                        <h4 class="mb-3">TES 3</h4>
                        <form @submit.prevent="onSubmit">
                            <table class="table table-bordered table-sm" border="1">
                                <tbody>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">1</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya adalah pekerja keras</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref1" value="A" v-model="val1"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya tidak mudah murung</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val1"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">2</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya ingin bekerja lebih baik daripada orang lain</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref2" value="A" v-model="val2"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya ingin melaksanakan apa yang saya kerjakan sampai selesai</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val2"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">3</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka menunjukkan kepada orang lain bagaimana cara mengerjakan sesuatu</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref3" value="A" v-model="val3"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya ingin berusaha sebaik mungkin</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val3"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">4</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya melakukan hal-hal yang lucu</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref4" value="A" v-model="val4"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya suka mengatakan kepada orang lain apa yang harus dikerjakan</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val4"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">5</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka bergabung didalam kelompok</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref5" value="A" v-model="val5"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya ingin diperhatikan didalam kelompok</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val5"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">6</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka membuat persahabatan yang akrab</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref6" value="A" v-model="val6"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya suka berteman dalam suatu kelompok</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val6"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">7</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya cepat merubah pikiran saya jika memang perlu</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref7" value="A" v-model="val7"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya suka berteman dalam suatu kelompok</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val7"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">8</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka membalas jika benar-benar disakiti</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref8" value="A" v-model="val8"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya suka mengerjakan sesuatu hal yang baru dan berbeda</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val8"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">9</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya ingin disukai atasan saya</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref9" value="A" v-model="val9"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya suka memberitahu orang lain jika mereka berbuat salah</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val9"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">10</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya senang mengikuti petunjuk-petunjuk yang diberikan kepada saya</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref10" value="A" v-model="val10"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya ingin menyenangkan atasan-atasan saya</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val10"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">11</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya berusaha keras</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref11" value="A" v-model="val11"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya adalah seorang yang rapi saya menempatkan segala sesuatu ditempatnya</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val11"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">12</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya mampu mempengaruhi orang lain untuk melakukan apa yang saya kehendaki</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref12" value="A" v-model="val12"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya tidak mudah marah</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val12"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">13</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka memberitahukan apa yang harus dikerjakan oleh kelompok</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref13" value="A" v-model="val13"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya selalu mengerjakan tugas sampai selesai</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val13"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">14</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya ingin kelihatan gembira dan menarik</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref14" value="A" v-model="val14"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya ingin betul-betul berhasil</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val14"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">15</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka menyesuaikan diri dalam kelompok</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref15" value="A" v-model="val15"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya suka membantu orang dalam memutuskan sesuatu</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val15"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">16</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya cemas bila ada yang tidak menyukai saya</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref16" value="A" v-model="val16"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya senang bila diperhatikan orang</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val16"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">17</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya senang mencoba hal-hal yang baru</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref17" value="A" v-model="val17"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya lebih suka bekerja dengan orang lain daripada sendirian</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val17"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">18</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya kadang-kadang menyalahkan orang lain bila ada sesuatu yang tidak beres</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref18" value="A" v-model="val18"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya merasa terganggu bila ada yang tidak menyukai saya</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val18"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">19</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya ingin menyenangkan atasan-atasan saya</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref19" value="A" v-model="val19"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya suka mencoba suatu pekerjaan yang baru dan berbeda</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val19"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">20</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka penjelasan-penjelasan terperinci dalam bekerja</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref20" value="A" v-model="val20"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya suka berterus terang bila ada orang yang menjengkelkan saya</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val20"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">21</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya selalu berusaha keras</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref21" value="A" v-model="val21"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya suka mengerjakan setiap langkah pekerjaan dengan hati-hati</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val21"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">22</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya memimpin dengan baik</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref22" value="A" v-model="val22"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya mengatur pekerjaan dengan baik</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val22"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">23</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya mudah marah</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref23" value="A" v-model="val23"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya lamban dalam membuat keputusan-keputusan</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val23"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">24</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya senang mengerjakan beberapa tugas pada saat yang sama</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref24" value="A" v-model="val24"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Dalam kelompok, saya lebih suka diam</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val24"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">25</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya senang diundang</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref25" value="A" v-model="val25"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya ingin mengerjakan segala sesuatunya lebih baik dari orang lain</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val25"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">26</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka membuat persahabatan yang akrab</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref26" value="A" v-model="val26"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya suka memberi nasehat kepada orang lain</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val26"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">27</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka mengerjakan sesuatu hal yang baru dan berbeda</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref27" value="A" v-model="val27"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya senang menceritakan keberhasilan saya</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val27"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">28</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Jika memang saya benar, saya akan mempertahankannya</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref28" value="A" v-model="val28"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya suka menjadi anggota kelompok</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val28"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">29</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya menghindari suatu perbedaan pendapat</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref29" value="A" v-model="val29"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya berusaha untuk lebih akrab dengan orang-orang</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val29"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">30</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka diberitahukan bagaimana melaksanakan tugas</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref30" value="A" v-model="val30"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya mudah merasa bosan</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val30"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">31</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya bekerja keras</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref31" value="A" v-model="val31"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya banyak berfikir dan membuat rencana</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val31"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">32</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya memimpin suatu kelompok</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref32" value="A" v-model="val32"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Hal-hal yang detail menarik minat saya</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val32"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">33</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya membuat keputusan dengan mudah dan cepat</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref33" value="A" v-model="val33"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya merawat barang-barang saya dengan rapi dan teratur</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val33"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">34</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya mengerjakan sesuatu dengan cepat</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref34" value="A" v-model="val34"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya jarang marah atau sedih</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val34"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">35</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya ingin menjadi bagian dari suatu kelompok</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref35" value="A" v-model="val35"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya hanya ingin mengerjakan satu pekerjaan dalam satu saat</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val35"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">36</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya berusaha membuat teman akrab</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref36" value="A" v-model="val36"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya berusaha keras untuk menjadi yang paling baik</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val36"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">37</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka baju-baju dan mobil-mobil model mutakhir</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref37" value="A" v-model="val37"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya senang bertanggung jawab atas orang lain</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val37"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">38</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya senang berdebat</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref38" value="A" v-model="val38"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya senang mendapat perhatian</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val38"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">39</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka menyenangkan atasan-atasan saya</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref39" value="A" v-model="val39"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya tertarik untuk menjadi bagian dari suatu kelompok</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val39"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">40</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka mematuhi peraturan dengan sungguh-sungguh</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref40" value="A" v-model="val40"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya ingin agar orang-orang benar-benar mengenal saya</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val40"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">41</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya berusaha keras</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref41" value="A" v-model="val41"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya sangat ramah</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val41"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">42</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Orang-orang menganggap saya adalah pemimpin yang baik</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref42" value="A" v-model="val42"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya berfikir panjang dan hati-hati</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val42"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">43</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya sering mengambil kesempatan-kesempatan yang ada</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref43" value="A" v-model="val43"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya senang mengurusi hal-hal yang kecil</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val43"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">44</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Orang-orang menganggap saya bekerja dengan cepat</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref44" value="A" v-model="val44"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Orang-orang menganggap saya merawat sesuatu dengan rapi</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val44"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">45</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka berolahraga</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref45" value="A" v-model="val45"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya sangat menyenangkan</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val45"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">46</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya senang jika orang-orang akrab dan ramah</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref46" value="A" v-model="val46"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya selalu berusaha menyelesaikan apa yang saya kerjakan</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val46"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">47</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka bereksperimen dan mencoba hal-hal yang baru</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref47" value="A" v-model="val47"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya senang menyelesaikan dengan baik pekerjaan yang sulit</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val47"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">48</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya senang diperlakukan secara adil</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref48" value="A" v-model="val48"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya suka memberitahu orang bagaimana mengerjakan sesuatu</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val48"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">49</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka melakukan apa yang diharapkan dari saya</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref49" value="A" v-model="val49"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya senang mendapat perhatian</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val49"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">50</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka penjelasan-penjelasan yang terperinci dalam bekerja</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref50" value="A" v-model="val50"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya senang berada diantara orang-orang</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val50"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">51</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya selalu berusaha menyelesaikan tugas-tugas secara sempurna</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref51" value="A" v-model="val51"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya adalah orang yang tak mengenal lelah</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val51"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">52</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya adalah tipe pemimpin</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref52" value="A" v-model="val52"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya mudah berteman</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val52"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">53</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka mengambil kesempatan-kesempatan yang ada</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref53" value="A" v-model="val53"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya banyak berfikir</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val53"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">54</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya bekerja dengan cepat dan mantap</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref54" value="A" v-model="val54"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya senang bekerja sampai pada hal yang sekecil-kecilnya</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val54"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">55</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya mempunyai banyak tenaga untuk berolah raga dan bermain</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref55" value="A" v-model="val55"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya merawat barang-barang saya dengan rapi dan teratur</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val55"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">56</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya berhubungan baik dengan semua orang</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref56" value="A" v-model="val56"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya bertabiat mantap dan tenang</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val56"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">57</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka bertemu dengan orang-orang baru dan mengerjakan sesuatu yang baru</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref57" value="A" v-model="val57"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya selalu ingin menyelesaikan pekerjaan yang saya mulai</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val57"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">58</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya biasanya mempertahankan apa yang saya yakini</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref58" value="A" v-model="val58"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya biasanya suka bekerja keras</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val58"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">59</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya senang saran-saran dari orang yang saya hormati</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref59" value="A" v-model="val59"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya senang bertanggung jawab atas orang lain</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val59"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">60</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya membiarkan orang lain mempengaruhi saya</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref60" value="A" v-model="val60"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya senang mendapatkan banyak perhatian</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val60"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">61</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya biasanya bekerja keras</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref61" value="A" v-model="val61"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya biasanya bekerja cepat</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val61"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">62</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Bila saya berbicara orang-orang mendengarkan</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref62" value="A" v-model="val62"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya sangat mahir menggunakan peralatan</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val62"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">63</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya lamban dalam membuat persahabatan</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref63" value="A" v-model="val63"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya lamban dalam memutuskan sesuatu</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val63"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">64</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya biasanya makan dengan cepat</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref64" value="A" v-model="val64"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya senang membaca</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val64"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">65</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya menyukai pekerjaan dimana saya dapat berkeliling</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref65" value="A" v-model="val65"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya senang pada pekerjaan yang membutuhkan ketelitian</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val65"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">66</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya mencari teman sebanyak mungkin</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref66" value="A" v-model="val66"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya tahu hal-hal apa yang tidak perlu</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val66"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">67</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya merencanakan sesuatu jauh-jauh sebelumnya</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref67" value="A" v-model="val67"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya selalu menyenangkan</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val67"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">68</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya bangga akan ketenaran saya</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref68" value="A" v-model="val68"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya memusatkan perhatian pada satu persoalan sampai persoalan tersebut terpecahkan</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val68"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">69</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka menyenangkan orang-orang yang saya hormati</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref69" value="A" v-model="val69"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya ingin berhasil</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val69"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">70</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya senang orang lain yang membuat keputusan untuk kelompok</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref70" value="A" v-model="val70"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya suka membuat keputusan untuk kelompok</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val70"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">71</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya selalu berusaha keras</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref71" value="A" v-model="val71"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya memutuskan sesuatu dengan mudah dan cepat</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val71"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">72</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Kelompok biasanya menjalankan apa yang saya inginkan</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref72" value="A" v-model="val72"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya terlalu tergesa-gesa</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val72"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">73</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya sering merasa lelah</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref73" value="A" v-model="val73"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya lamban dalam memutuskan sesuatu</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val73"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">74</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya bekerja dengan cepat</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref74" value="A" v-model="val74"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya mudah berteman</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val74"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">75</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya biasanya bersemangat dan bergairah</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref75" value="A" v-model="val75"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya memerlukan banyak waktu untuk berfikir</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val75"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">76</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya sangat ramah kepada orang</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref76" value="A" v-model="val76"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya senang pada pekerjaan yang membutuhkan ketepatan</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val76"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">77</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya banyak sekali berfikir dan membuat rencana</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref77" value="A" v-model="val77"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya meletakkan segalanya pada tempatnya</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val77"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">78</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya senang pada pekerjaan yang membutuhkan ketelitian</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref78" value="A" v-model="val78"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya tidak mudah marah</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val78"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">79</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka menurut pada orang-orang yang saya kagumi</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref79" value="A" v-model="val79"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya selalu menyelesaikan pekerjaan yang saya mulai</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val79"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">80</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya senang mengikuti petunjuk-petunjuk yang jelas</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref80" value="A" v-model="val80"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya senang bekerja keras</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val80"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">81</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya berusaha mendapatkan apa yang saya inginkan</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref81" value="A" v-model="val81"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya seorang pemimpin yang baik</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val81"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">82</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya menyuruh orang untuk bekerja keras</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref82" value="A" v-model="val82"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya adalah orang yang kurang berfikir panjang</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val82"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">83</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya memutuskan sesuatu dengan cepat</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref83" value="A" v-model="val83"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya berbicara dengan cepat</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val83"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">84</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya biasa bekerja tergesa-gesa</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref84" value="A" v-model="val84"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya berlatih / berolah raga dengan teratur</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val84"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">85</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya tidak suka menjumpai orang</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref85" value="A" v-model="val85"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya cepat merasa lelah</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val85"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">86</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya banyak membuat persahabatan</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref86" value="A" v-model="val86"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya memerlukan banyak waktu untuk berfikir</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val86"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">87</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka menerapkan teori-teori dalam bekerja</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref87" value="A" v-model="val87"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya suka mengerjakan sesuatu sampai pada hal yang sekecil-kecilnya</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val87"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">88</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya suka mengerjakan sesuatu sampai pada hal yang sekecil-kecilnya</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref88" value="A" v-model="val88"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya suka mengatur pekerjaan saya</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val88"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">89</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya meletakkan segalanya pada tempatnya</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref89" value="A" v-model="val89"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya selalu menyenangkan</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val89"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th rowspan="2" class="text-center align-middle">90</th>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)">A.</td>
                                        <td style="background-color:rgba(0,0,0,0.05)">Saya senang diberi petunjuk apa yang harus dikerjakan</td>
                                        <td class="text-center" style="background-color:rgba(0,0,0,0.05)"><input type="radio" ref="ref90" value="A" v-model="val90"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">B.</td>
                                        <td>Saya harus menyelesaikan apa yang saya mulai</td>
                                        <td class="text-center"><input type="radio" value="B" v-model="val90"></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <button class="w-100 btn btn-primary btn-lg" type="submit" v-if="btn_submit">Kirim</button>
                        </form>
                    </div>
                    <div class="col-md-5 col-lg-4">
                        <div class="position-sticky" style="top: 35vh;">
                            <div class="p-3 mb-4 bg-light rounded-3 text-center">
                                <h4 class="mb-3">TES 3</h4>
                                <div class="container-fluid py-3">
                                    <div class="card bg-danger text-white fw-bold" id="countdown60">
                                        <div class="values card-body"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    var { Timer } = require('easytimer.js');
    import Swal from 'sweetalert2'
    export default{
        data(){
            return {
                par_checker : false,
                val1 : null,
                val2 : null,
                val3 : null,
                val4 : null,
                val5 : null,
                val6 : null,
                val7 : null,
                val8 : null,
                val9 : null,
                val10 : null,
                val11 : null,
                val12 : null,
                val13 : null,
                val14 : null,
                val15 : null,
                val16 : null,
                val17 : null,
                val18 : null,
                val19 : null,
                val20 : null,
                val21 : null,
                val22 : null,
                val23 : null,
                val24 : null,
                val25 : null,
                val26 : null,
                val27 : null,
                val28 : null,
                val29 : null,
                val30 : null,
                val31 : null,
                val32 : null,
                val33 : null,
                val34 : null,
                val35 : null,
                val36 : null,
                val37 : null,
                val38 : null,
                val39 : null,
                val40 : null,
                val41 : null,
                val42 : null,
                val43 : null,
                val44 : null,
                val45 : null,
                val46 : null,
                val47 : null,
                val48 : null,
                val49 : null,
                val50 : null,
                val51 : null,
                val52 : null,
                val53 : null,
                val54 : null,
                val55 : null,
                val56 : null,
                val57 : null,
                val58 : null,
                val59 : null,
                val60 : null,
                val61 : null,
                val62 : null,
                val63 : null,
                val64 : null,
                val65 : null,
                val66 : null,
                val67 : null,
                val68 : null,
                val69 : null,
                val70 : null,
                val71 : null,
                val72 : null,
                val73 : null,
                val74 : null,
                val75 : null,
                val76 : null,
                val77 : null,
                val78 : null,
                val79 : null,
                val80 : null,
                val81 : null,
                val82 : null,
                val83 : null,
                val84 : null,
                val85 : null,
                val86 : null,
                val87 : null,
                val88 : null,
                val89 : null,
                val90 : null,
                btn_submit : true
            }
        },
        methods : {
            async onSubmit() {
                if(!(this.val1 && this.val2 && this.val3 && this.val4 && this.val5 && this.val6 && this.val7 && this.val8 && this.val9 && this.val10 && this.val11 && this.val12 && this.val13 && this.val14 && this.val15 && this.val16 && this.val17 && this.val18 && this.val19 && this.val20 && this.val21 && this.val22 && this.val23 && this.val24 && this.val25 && this.val26 && this.val27 && this.val28 && this.val29 && this.val30 && this.val31 && this.val32 && this.val33 && this.val34 && this.val35 && this.val36 && this.val37 && this.val38 && this.val39 && this.val40 && this.val41 && this.val42 && this.val43 && this.val44 && this.val45 && this.val46 && this.val47 && this.val48 && this.val49 && this.val50 && this.val51 && this.val52 && this.val53 && this.val54 && this.val55 && this.val56 && this.val57 && this.val58 && this.val59 && this.val60 && this.val61 && this.val62 && this.val63 && this.val64 && this.val65 && this.val66 && this.val67 && this.val68 && this.val69 && this.val70 && this.val71 && this.val72 && this.val73 && this.val74 && this.val75 && this.val76 && this.val77 && this.val78 && this.val79 && this.val80 && this.val81 && this.val82 && this.val83 && this.val84 && this.val85 && this.val86 && this.val87 && this.val88 && this.val89 && this.val90)){
                    this.$store.dispatch('setPapiAlert',true)
                    this.checker(false)
                    return
                }
                try {
                    let obj = {
                        method: 'post',
                        url: this.$store.getters.getServerURL + 'papi',
                        data: {
                            id: this.$store.getters.getId,

                            val1: this.val1,
                            val2: this.val2,
                            val3: this.val3,
                            val4: this.val4,
                            val5: this.val5,
                            val6: this.val6,
                            val7: this.val7,
                            val8: this.val8,
                            val9: this.val9,
                            val10: this.val10,

                            val11: this.val11,
                            val12: this.val12,
                            val13: this.val13,
                            val14: this.val14,
                            val15: this.val15,
                            val16: this.val16,
                            val17: this.val17,
                            val18: this.val18,
                            val19: this.val19,
                            val20: this.val20,

                            val21: this.val21,
                            val22: this.val22,
                            val23: this.val23,
                            val24: this.val24,
                            val25: this.val25,
                            val26: this.val26,
                            val27: this.val27,
                            val28: this.val28,
                            val29: this.val29,
                            val30: this.val30,

                            val31: this.val31,
                            val32: this.val32,
                            val33: this.val33,
                            val34: this.val34,
                            val35: this.val35,
                            val36: this.val36,
                            val37: this.val37,
                            val38: this.val38,
                            val39: this.val39,
                            val40: this.val40,

                            val41: this.val41,
                            val42: this.val42,
                            val43: this.val43,
                            val44: this.val44,
                            val45: this.val45,
                            val46: this.val46,
                            val47: this.val47,
                            val48: this.val48,
                            val49: this.val49,
                            val50: this.val50,

                            val51: this.val51,
                            val52: this.val52,
                            val53: this.val53,
                            val54: this.val54,
                            val55: this.val55,
                            val56: this.val56,
                            val57: this.val57,
                            val58: this.val58,
                            val59: this.val59,
                            val60: this.val60,

                            val61: this.val61,
                            val62: this.val62,
                            val63: this.val63,
                            val64: this.val64,
                            val65: this.val65,
                            val66: this.val66,
                            val67: this.val67,
                            val68: this.val68,
                            val69: this.val69,
                            val70: this.val70,

                            val71: this.val71,
                            val72: this.val72,
                            val73: this.val73,
                            val74: this.val74,
                            val75: this.val75,
                            val76: this.val76,
                            val77: this.val77,
                            val78: this.val78,
                            val79: this.val79,
                            val80: this.val80,

                            val81: this.val81,
                            val82: this.val82,
                            val83: this.val83,
                            val84: this.val84,
                            val85: this.val85,
                            val86: this.val86,
                            val87: this.val87,
                            val88: this.val88,
                            val89: this.val89,
                            val90: this.val90
                        }
                    }
                    console.log('REQ :',obj)
                    var response = await axios(obj)
                    console.log('RES :',response.data)
                    if(response.data.data){
                        this.$store.dispatch('setPagePapiSoal',response.data.data[0].id)
                        this.$router.replace({name: 'end'})
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'Peringatan',
                        text: 'Gagal Kirim Data. Tombol "Kirim" Ditampilkan. Coba Lagi dengan Menekan tombol "Kirim"',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    console.error(error)
                }
            },
            checker(byTime=true){
                if(!this.$store.getters.getPapiAlert) return
                if(!this.val1){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 1 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref1.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val2){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 2 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref2.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val3){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 3 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref3.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val4){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 4 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref4.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val5){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 5 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref5.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val6){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 6 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref6.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val7){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 7 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref7.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val8){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 8 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref8.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val9){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 9 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref9.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val10){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 10 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref10.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val11){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 11 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref11.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val12){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 12 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref12.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val13){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 13 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref13.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val14){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 14 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref14.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val15){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 15 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref15.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val16){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 16 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref16.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val17){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 17 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref17.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val18){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 18 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref18.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val19){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 19 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref19.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val20){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 20 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref20.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val21){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 21 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref21.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val22){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 22 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref22.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val23){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 23 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref23.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val24){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 24 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref24.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val25){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 25 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref25.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val26){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 26 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref26.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val27){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 27 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref27.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val28){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 28 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref28.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val29){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 29 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref29.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val30){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 30 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref30.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val31){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 31 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref31.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val32){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 32 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref32.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val33){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 33 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref33.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val34){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 34 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref34.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val35){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 35 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref35.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val36){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 36 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref36.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val37){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 37 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref37.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val38){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 38 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref38.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val39){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 39 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref39.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val40){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 40 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref40.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val41){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 41 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref41.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val42){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 42 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref42.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val43){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 43 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref43.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val44){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 44 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref44.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val45){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 45 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref45.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val46){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 46 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref46.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val47){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 47 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref47.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val48){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 48 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref48.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val49){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 49 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref49.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val50){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 50 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref50.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val51){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 51 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref51.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val52){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 52 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref52.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val53){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 53 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref53.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val54){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 54 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref54.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val55){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 55 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref55.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val56){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 56 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref56.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val57){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 57 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref57.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val58){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 58 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref58.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val59){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 59 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref59.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val60){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 60 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref60.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val61){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 61 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref61.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val62){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 62 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref62.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val63){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 63 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref63.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val64){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 64 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref64.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val65){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 65 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref65.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val66){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 66 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref66.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val67){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 67 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref67.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val68){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 68 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref68.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val69){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 69 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref69.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val70){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 70 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref70.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val71){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 71 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref71.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val72){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 72 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref72.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val73){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 73 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref73.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val74){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 74 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref74.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val75){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 75 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref75.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val76){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 76 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref76.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val77){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 77 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref77.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val78){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 78 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref78.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val79){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 79 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref79.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val80){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 80 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref80.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val81){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 81 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref81.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val82){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 82 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref82.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val83){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 83 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref83.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val84){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 84 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref84.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val85){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 85 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref85.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val86){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 86 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref86.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val87){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 87 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref87.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val88){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 88 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref88.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val89){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 89 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref89.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }


                else if(!this.val90){
                    Swal.fire({
                        text: 'Semua Pernyataan Harus Dipilih Salah Satu',
                        title: 'Pernyataan 90 Belum Dipilih',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.$refs.ref90.focus()
                    if(byTime) setInterval(() => {this.checker()}, 14000)
                }
            }
        },
        async mounted(){
            console.log('WELCOME PAPIKOSTICK')
            var timer = new Timer();
            timer.start({countdown: true, startValues: {seconds: this.$store.getters.getPapiInterval}});

            $('#countdown60 .values').html(timer.getTimeValues().toString());

            timer.addEventListener('secondsUpdated', (e)=>{
                $('#countdown60 .values').html(timer.getTimeValues().toString());
            });

            timer.addEventListener('targetAchieved', (e)=>{
                this.$store.dispatch('setPapiAlert',true)
                if(!this.par_checker) this.checker()
                this.par_checker = true
            });

            // window.addEventListener('keyup', (ev) => {
            //     if(ev.keyCode == 65 || ev.keyCode == 66){
            //         const Toast = Swal.mixin({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, timerProgressBar: true })
            //         if(this.val1 === null) { this.val1 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 1 Dijawab ${this.val1}` }); return; }
            //         if(this.val2 === null) { this.val2 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 2 Dijawab ${this.val2}` }); return; }
            //         if(this.val3 === null) { this.val3 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 3 Dijawab ${this.val3}` }); return; }
            //         if(this.val4 === null) { this.val4 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 4 Dijawab ${this.val4}` }); return; }
            //         if(this.val5 === null) { this.val5 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 5 Dijawab ${this.val5}` }); return; }
            //         if(this.val6 === null) { this.val6 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 6 Dijawab ${this.val6}` }); return; }
            //         if(this.val7 === null) { this.val7 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 7 Dijawab ${this.val7}` }); return; }
            //         if(this.val8 === null) { this.val8 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 8 Dijawab ${this.val8}` }); return; }
            //         if(this.val9 === null) { this.val9 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 9 Dijawab ${this.val9}` }); return; }
            //         if(this.val10 === null) { this.val10 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 10 Dijawab ${this.val10}` }); return; }
            //         if(this.val11 === null) { this.val11 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 11 Dijawab ${this.val11}` }); return; }
            //         if(this.val12 === null) { this.val12 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 12 Dijawab ${this.val12}` }); return; }
            //         if(this.val13 === null) { this.val13 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 13 Dijawab ${this.val13}` }); return; }
            //         if(this.val14 === null) { this.val14 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 14 Dijawab ${this.val14}` }); return; }
            //         if(this.val15 === null) { this.val15 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 15 Dijawab ${this.val15}` }); return; }
            //         if(this.val16 === null) { this.val16 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 16 Dijawab ${this.val16}` }); return; }
            //         if(this.val17 === null) { this.val17 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 17 Dijawab ${this.val17}` }); return; }
            //         if(this.val18 === null) { this.val18 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 18 Dijawab ${this.val18}` }); return; }
            //         if(this.val19 === null) { this.val19 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 19 Dijawab ${this.val19}` }); return; }
            //         if(this.val20 === null) { this.val20 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 20 Dijawab ${this.val20}` }); return; }
            //         if(this.val21 === null) { this.val21 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 21 Dijawab ${this.val21}` }); return; }
            //         if(this.val22 === null) { this.val22 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 22 Dijawab ${this.val22}` }); return; }
            //         if(this.val23 === null) { this.val23 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 23 Dijawab ${this.val23}` }); return; }
            //         if(this.val24 === null) { this.val24 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 24 Dijawab ${this.val24}` }); return; }
            //         if(this.val25 === null) { this.val25 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 25 Dijawab ${this.val25}` }); return; }
            //         if(this.val26 === null) { this.val26 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 26 Dijawab ${this.val26}` }); return; }
            //         if(this.val27 === null) { this.val27 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 27 Dijawab ${this.val27}` }); return; }
            //         if(this.val28 === null) { this.val28 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 28 Dijawab ${this.val28}` }); return; }
            //         if(this.val29 === null) { this.val29 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 29 Dijawab ${this.val29}` }); return; }
            //         if(this.val30 === null) { this.val30 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 30 Dijawab ${this.val30}` }); return; }
            //         if(this.val31 === null) { this.val31 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 31 Dijawab ${this.val31}` }); return; }
            //         if(this.val32 === null) { this.val32 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 32 Dijawab ${this.val32}` }); return; }
            //         if(this.val33 === null) { this.val33 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 33 Dijawab ${this.val33}` }); return; }
            //         if(this.val34 === null) { this.val34 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 34 Dijawab ${this.val34}` }); return; }
            //         if(this.val35 === null) { this.val35 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 35 Dijawab ${this.val35}` }); return; }
            //         if(this.val36 === null) { this.val36 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 36 Dijawab ${this.val36}` }); return; }
            //         if(this.val37 === null) { this.val37 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 37 Dijawab ${this.val37}` }); return; }
            //         if(this.val38 === null) { this.val38 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 38 Dijawab ${this.val38}` }); return; }
            //         if(this.val39 === null) { this.val39 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 39 Dijawab ${this.val39}` }); return; }
            //         if(this.val40 === null) { this.val40 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 40 Dijawab ${this.val40}` }); return; }
            //         if(this.val41 === null) { this.val41 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 41 Dijawab ${this.val41}` }); return; }
            //         if(this.val42 === null) { this.val42 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 42 Dijawab ${this.val42}` }); return; }
            //         if(this.val43 === null) { this.val43 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 43 Dijawab ${this.val43}` }); return; }
            //         if(this.val44 === null) { this.val44 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 44 Dijawab ${this.val44}` }); return; }
            //         if(this.val45 === null) { this.val45 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 45 Dijawab ${this.val45}` }); return; }
            //         if(this.val46 === null) { this.val46 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 46 Dijawab ${this.val46}` }); return; }
            //         if(this.val47 === null) { this.val47 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 47 Dijawab ${this.val47}` }); return; }
            //         if(this.val48 === null) { this.val48 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 48 Dijawab ${this.val48}` }); return; }
            //         if(this.val49 === null) { this.val49 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 49 Dijawab ${this.val49}` }); return; }
            //         if(this.val50 === null) { this.val50 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 50 Dijawab ${this.val50}` }); return; }
            //         if(this.val51 === null) { this.val51 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 51 Dijawab ${this.val51}` }); return; }
            //         if(this.val52 === null) { this.val52 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 52 Dijawab ${this.val52}` }); return; }
            //         if(this.val53 === null) { this.val53 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 53 Dijawab ${this.val53}` }); return; }
            //         if(this.val54 === null) { this.val54 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 54 Dijawab ${this.val54}` }); return; }
            //         if(this.val55 === null) { this.val55 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 55 Dijawab ${this.val55}` }); return; }
            //         if(this.val56 === null) { this.val56 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 56 Dijawab ${this.val56}` }); return; }
            //         if(this.val57 === null) { this.val57 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 57 Dijawab ${this.val57}` }); return; }
            //         if(this.val58 === null) { this.val58 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 58 Dijawab ${this.val58}` }); return; }
            //         if(this.val59 === null) { this.val59 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 59 Dijawab ${this.val59}` }); return; }
            //         if(this.val60 === null) { this.val60 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 60 Dijawab ${this.val60}` }); return; }
            //         if(this.val61 === null) { this.val61 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 61 Dijawab ${this.val61}` }); return; }
            //         if(this.val62 === null) { this.val62 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 62 Dijawab ${this.val62}` }); return; }
            //         if(this.val63 === null) { this.val63 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 63 Dijawab ${this.val63}` }); return; }
            //         if(this.val64 === null) { this.val64 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 64 Dijawab ${this.val64}` }); return; }
            //         if(this.val65 === null) { this.val65 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 65 Dijawab ${this.val65}` }); return; }
            //         if(this.val66 === null) { this.val66 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 66 Dijawab ${this.val66}` }); return; }
            //         if(this.val67 === null) { this.val67 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 67 Dijawab ${this.val67}` }); return; }
            //         if(this.val68 === null) { this.val68 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 68 Dijawab ${this.val68}` }); return; }
            //         if(this.val69 === null) { this.val69 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 69 Dijawab ${this.val69}` }); return; }
            //         if(this.val70 === null) { this.val70 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 70 Dijawab ${this.val70}` }); return; }
            //         if(this.val71 === null) { this.val71 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 71 Dijawab ${this.val71}` }); return; }
            //         if(this.val72 === null) { this.val72 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 72 Dijawab ${this.val72}` }); return; }
            //         if(this.val73 === null) { this.val73 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 73 Dijawab ${this.val73}` }); return; }
            //         if(this.val74 === null) { this.val74 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 74 Dijawab ${this.val74}` }); return; }
            //         if(this.val75 === null) { this.val75 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 75 Dijawab ${this.val75}` }); return; }
            //         if(this.val76 === null) { this.val76 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 76 Dijawab ${this.val76}` }); return; }
            //         if(this.val77 === null) { this.val77 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 77 Dijawab ${this.val77}` }); return; }
            //         if(this.val78 === null) { this.val78 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 78 Dijawab ${this.val78}` }); return; }
            //         if(this.val79 === null) { this.val79 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 79 Dijawab ${this.val79}` }); return; }
            //         if(this.val80 === null) { this.val80 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 80 Dijawab ${this.val80}` }); return; }
            //         if(this.val81 === null) { this.val81 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 81 Dijawab ${this.val81}` }); return; }
            //         if(this.val82 === null) { this.val82 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 82 Dijawab ${this.val82}` }); return; }
            //         if(this.val83 === null) { this.val83 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 83 Dijawab ${this.val83}` }); return; }
            //         if(this.val84 === null) { this.val84 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 84 Dijawab ${this.val84}` }); return; }
            //         if(this.val85 === null) { this.val85 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 85 Dijawab ${this.val85}` }); return; }
            //         if(this.val86 === null) { this.val86 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 86 Dijawab ${this.val86}` }); return; }
            //         if(this.val87 === null) { this.val87 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 87 Dijawab ${this.val87}` }); return; }
            //         if(this.val88 === null) { this.val88 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 88 Dijawab ${this.val88}` }); return; }
            //         if(this.val89 === null) { this.val89 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 89 Dijawab ${this.val89}` }); return; }
            //         if(this.val90 === null) { this.val90 = ev.keyCode==65?'A':'B'; Toast.fire({ icon: 'success', title: `Pernyataan 90 Dijawab ${this.val90}` }); return; }
            //     }
            // })
        },
        unmounted(){
            this.$store.dispatch('setPapiAlert',false)
            console.log('GOOD BYE PAPIKOSTICK')
        }
    }
</script>