<template>
    <nav class="navbar navbar-expand-lg navbar-dark" aria-label="Tenth navbar example" style="background-color:#1f238d;">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample08" aria-controls="navbarsExample08" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-md-center" id="navbarsExample08">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">1</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">2</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">3</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">4</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">5</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">6</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">7</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">8</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">9</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">10</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">11</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">12</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">13</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page">14</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">15</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">16</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">17</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">18</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">19</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">20</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">21</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">22</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">23</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">24</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">25</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">26</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">27</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">28</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">29</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">30</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">31</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">32</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">33</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">34</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">35</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">36</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">37</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">38</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">39</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">40</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">41</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">42</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">43</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">44</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">45</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">46</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">47</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">48</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">49</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="font-size: 0.85rem;">50</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>