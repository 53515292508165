import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
    state: {
        debug 				: false,
        serverURL 			: 'https://lumen.sukunsigaretpsikotes.com/',
        temp_provinsi 		: '',
        temp_kab_kota 		: '',
        ID 					: '',
        lokasi 				: '',
        page_biodata 		: false,
        page_cfit_inst1 	: '',
        page_cfit_soal1 	: '',
        page_cfit_inst2 	: '',
        page_cfit_soal2 	: '',
        page_cfit_inst3 	: '',
        page_cfit_soal3 	: '',
        page_cfit_inst4 	: '',
        page_cfit_soal4 	: '',
        page_kraepelin_inst : '',
        page_kraepelin_sim1 : '',
        page_kraepelin_sim2 : '',
        page_kraepelin1 	: '',
        page_kraepelin2 	: '',
        page_kraepelin3 	: '',
        page_kraepelin4 	: '',
        page_kraepelin5 	: '',
        page_kraepelin6 	: '',
        page_kraepelin7 	: '',
        page_kraepelin8 	: '',
        page_kraepelin9 	: '',
        page_kraepelin10 	: '',
        page_kraepelin11 	: '',
        page_kraepelin12 	: '',
        page_kraepelin13 	: '',
        page_kraepelin14 	: '',
        page_kraepelin15 	: '',
        page_kraepelin16 	: '',
        page_kraepelin17 	: '',
        page_kraepelin18 	: '',
        page_kraepelin19 	: '',
        page_kraepelin20 	: '',
        page_kraepelin21 	: '',
        page_kraepelin22 	: '',
        page_kraepelin23 	: '',
        page_kraepelin24 	: '',
        page_kraepelin25 	: '',
        page_kraepelin26 	: '',
        page_kraepelin27 	: '',
        page_kraepelin28 	: '',
        page_kraepelin29 	: '',
        page_kraepelin30 	: '',
        page_kraepelin31 	: '',
        page_kraepelin32 	: '',
        page_kraepelin33 	: '',
        page_kraepelin34 	: '',
        page_kraepelin35 	: '',
        page_kraepelin36 	: '',
        page_kraepelin37 	: '',
        page_kraepelin38 	: '',
        page_kraepelin39 	: '',
        page_kraepelin40 	: '',
        page_kraepelin41 	: '',
        page_kraepelin42 	: '',
        page_kraepelin43 	: '',
        page_kraepelin44 	: '',
        page_kraepelin45 	: '',
        page_kraepelin46 	: '',
        page_kraepelin47 	: '',
        page_kraepelin48 	: '',
        page_kraepelin49 	: '',
        page_kraepelin50 	: '',
        page_papi_inst 		: '',
        page_papi_soal 		: '',
        papi_alert 			: false,
        cfit1_interval 		: 180,
        cfit2_interval 		: 240,
        cfit3_interval 		: 180,
        cfit4_interval 		: 150,
        kraepelin_interval 	: 20,
        papi_interval 		: 1200
    },
    getters: {
        getId(state){ return state.ID },
		getLokasi(state){ return state.lokasi },
		getDebug(state){ return state.debug },
		getServerURL(state){ return state.serverURL },
		getTempProvinsi(state){ return state.temp_provinsi },
		getTempKabKota(state){ return state.temp_kab_kota },
		getPageBiodata(state){ return state.page_biodata },
		getPageCfitInst1(state){ return state.page_cfit_inst1 },
		getPageCfitSoal1(state){ return state.page_cfit_soal1 },
		getPageCfitInst2(state){ return state.page_cfit_inst2 },
		getPageCfitSoal2(state){ return state.page_cfit_soal2 },
		getPageCfitInst3(state){ return state.page_cfit_inst3 },
		getPageCfitSoal3(state){ return state.page_cfit_soal3 },
		getPageCfitInst4(state){ return state.page_cfit_inst4 },
		getPageCfitSoal4(state){ return state.page_cfit_soal4 },
		getPageKraepelinInst(state){ return state.page_kraepelin_inst },
		getPageKraepelinSim1(state){ return state.page_kraepelin_sim1 },
		getPageKraepelinSim2(state){ return state.page_kraepelin_sim2 },
		getPageKraepelin1(state){ return state.page_kraepelin1 },
		getPageKraepelin2(state){ return state.page_kraepelin2 },
		getPageKraepelin3(state){ return state.page_kraepelin3 },
		getPageKraepelin4(state){ return state.page_kraepelin4 },
		getPageKraepelin5(state){ return state.page_kraepelin5 },
		getPageKraepelin6(state){ return state.page_kraepelin6 },
		getPageKraepelin7(state){ return state.page_kraepelin7 },
		getPageKraepelin8(state){ return state.page_kraepelin8 },
		getPageKraepelin9(state){ return state.page_kraepelin9 },
		getPageKraepelin10(state){ return state.page_kraepelin10 },
		getPageKraepelin11(state){ return state.page_kraepelin11 },
		getPageKraepelin12(state){ return state.page_kraepelin12 },
		getPageKraepelin13(state){ return state.page_kraepelin13 },
		getPageKraepelin14(state){ return state.page_kraepelin14 },
		getPageKraepelin15(state){ return state.page_kraepelin15 },
		getPageKraepelin16(state){ return state.page_kraepelin16 },
		getPageKraepelin17(state){ return state.page_kraepelin17 },
		getPageKraepelin18(state){ return state.page_kraepelin18 },
		getPageKraepelin19(state){ return state.page_kraepelin19 },
		getPageKraepelin20(state){ return state.page_kraepelin20 },
		getPageKraepelin21(state){ return state.page_kraepelin21 },
		getPageKraepelin22(state){ return state.page_kraepelin22 },
		getPageKraepelin23(state){ return state.page_kraepelin23 },
		getPageKraepelin24(state){ return state.page_kraepelin24 },
		getPageKraepelin25(state){ return state.page_kraepelin25 },
		getPageKraepelin26(state){ return state.page_kraepelin26 },
		getPageKraepelin27(state){ return state.page_kraepelin27 },
		getPageKraepelin28(state){ return state.page_kraepelin28 },
		getPageKraepelin29(state){ return state.page_kraepelin29 },
		getPageKraepelin30(state){ return state.page_kraepelin30 },
		getPageKraepelin31(state){ return state.page_kraepelin31 },
		getPageKraepelin32(state){ return state.page_kraepelin32 },
		getPageKraepelin33(state){ return state.page_kraepelin33 },
		getPageKraepelin34(state){ return state.page_kraepelin34 },
		getPageKraepelin35(state){ return state.page_kraepelin35 },
		getPageKraepelin36(state){ return state.page_kraepelin36 },
		getPageKraepelin37(state){ return state.page_kraepelin37 },
		getPageKraepelin38(state){ return state.page_kraepelin38 },
		getPageKraepelin39(state){ return state.page_kraepelin39 },
		getPageKraepelin40(state){ return state.page_kraepelin40 },
		getPageKraepelin41(state){ return state.page_kraepelin41 },
		getPageKraepelin42(state){ return state.page_kraepelin42 },
		getPageKraepelin43(state){ return state.page_kraepelin43 },
		getPageKraepelin44(state){ return state.page_kraepelin44 },
		getPageKraepelin45(state){ return state.page_kraepelin45 },
		getPageKraepelin46(state){ return state.page_kraepelin46 },
		getPageKraepelin47(state){ return state.page_kraepelin47 },
		getPageKraepelin48(state){ return state.page_kraepelin48 },
		getPageKraepelin49(state){ return state.page_kraepelin49 },
		getPageKraepelin50(state){ return state.page_kraepelin50 },
		getPagePapiInst(state){ return state.page_papi_inst },
		getPagePapiSoal(state){ return state.page_papi_soal },
		getPapiAlert(state){ return state.papi_alert },
		getKraepelinInterval(state){ return state.kraepelin_interval },
		getCFIT1Interval(state){ return state.cfit1_interval },
		getCFIT2Interval(state){ return state.cfit2_interval },
		getCFIT3Interval(state){ return state.cfit3_interval },
		getCFIT4Interval(state){ return state.cfit4_interval },
		getPapiInterval(state){ return state.papi_interval }
    },
    mutations: {
        mutTempProvinsi(state,payload){ state.temp_provinsi = payload },
		mutTempKabKota(state,payload){ state.temp_kab_kota = payload },
		mutId(state,payload){ state.ID = payload },
		mutLokasi(state,payload){ state.lokasi = payload },
		mutPageBiodata(state,payload){ state.page_biodata = payload },
		mutPageCfitInst1(state,payload){ state.page_cfit_inst1 = payload },
		mutPageCfitSoal1(state,payload){ state.page_cfit_soal1 = payload },
		mutPageCfitInst2(state,payload){ state.page_cfit_inst2 = payload },
		mutPageCfitSoal2(state,payload){ state.page_cfit_soal2 = payload },
		mutPageCfitInst3(state,payload){ state.page_cfit_inst3 = payload },
		mutPageCfitSoal3(state,payload){ state.page_cfit_soal3 = payload },
		mutPageCfitInst4(state,payload){ state.page_cfit_inst4 = payload },
		mutPageCfitSoal4(state,payload){ state.page_cfit_soal4 = payload },
		mutPageKraepelinInst(state,payload){ state.page_kraepelin_inst = payload },
		mutPageKraepelinSim1(state,payload){ state.page_kraepelin_sim1 = payload },
		mutPageKraepelinSim2(state,payload){ state.page_kraepelin_sim2 = payload },
		mutPageKraepelin1(state,payload){ state.page_kraepelin1 = payload },
		mutPageKraepelin2(state,payload){ state.page_kraepelin2 = payload },
		mutPageKraepelin3(state,payload){ state.page_kraepelin3 = payload },
		mutPageKraepelin4(state,payload){ state.page_kraepelin4 = payload },
		mutPageKraepelin5(state,payload){ state.page_kraepelin5 = payload },
		mutPageKraepelin6(state,payload){ state.page_kraepelin6 = payload },
		mutPageKraepelin7(state,payload){ state.page_kraepelin7 = payload },
		mutPageKraepelin8(state,payload){ state.page_kraepelin8 = payload },
		mutPageKraepelin9(state,payload){ state.page_kraepelin9 = payload },
		mutPageKraepelin10(state,payload){ state.page_kraepelin10 = payload },
		mutPageKraepelin11(state,payload){ state.page_kraepelin11 = payload },
		mutPageKraepelin12(state,payload){ state.page_kraepelin12 = payload },
		mutPageKraepelin13(state,payload){ state.page_kraepelin13 = payload },
		mutPageKraepelin14(state,payload){ state.page_kraepelin14 = payload },
		mutPageKraepelin15(state,payload){ state.page_kraepelin15 = payload },
		mutPageKraepelin16(state,payload){ state.page_kraepelin16 = payload },
		mutPageKraepelin17(state,payload){ state.page_kraepelin17 = payload },
		mutPageKraepelin18(state,payload){ state.page_kraepelin18 = payload },
		mutPageKraepelin19(state,payload){ state.page_kraepelin19 = payload },
		mutPageKraepelin20(state,payload){ state.page_kraepelin20 = payload },
		mutPageKraepelin21(state,payload){ state.page_kraepelin21 = payload },
		mutPageKraepelin22(state,payload){ state.page_kraepelin22 = payload },
		mutPageKraepelin23(state,payload){ state.page_kraepelin23 = payload },
		mutPageKraepelin24(state,payload){ state.page_kraepelin24 = payload },
		mutPageKraepelin25(state,payload){ state.page_kraepelin25 = payload },
		mutPageKraepelin26(state,payload){ state.page_kraepelin26 = payload },
		mutPageKraepelin27(state,payload){ state.page_kraepelin27 = payload },
		mutPageKraepelin28(state,payload){ state.page_kraepelin28 = payload },
		mutPageKraepelin29(state,payload){ state.page_kraepelin29 = payload },
		mutPageKraepelin30(state,payload){ state.page_kraepelin30 = payload },
		mutPageKraepelin31(state,payload){ state.page_kraepelin31 = payload },
		mutPageKraepelin32(state,payload){ state.page_kraepelin32 = payload },
		mutPageKraepelin33(state,payload){ state.page_kraepelin33 = payload },
		mutPageKraepelin34(state,payload){ state.page_kraepelin34 = payload },
		mutPageKraepelin35(state,payload){ state.page_kraepelin35 = payload },
		mutPageKraepelin36(state,payload){ state.page_kraepelin36 = payload },
		mutPageKraepelin37(state,payload){ state.page_kraepelin37 = payload },
		mutPageKraepelin38(state,payload){ state.page_kraepelin38 = payload },
		mutPageKraepelin39(state,payload){ state.page_kraepelin39 = payload },
		mutPageKraepelin40(state,payload){ state.page_kraepelin40 = payload },
		mutPageKraepelin41(state,payload){ state.page_kraepelin41 = payload },
		mutPageKraepelin42(state,payload){ state.page_kraepelin42 = payload },
		mutPageKraepelin43(state,payload){ state.page_kraepelin43 = payload },
		mutPageKraepelin44(state,payload){ state.page_kraepelin44 = payload },
		mutPageKraepelin45(state,payload){ state.page_kraepelin45 = payload },
		mutPageKraepelin46(state,payload){ state.page_kraepelin46 = payload },
		mutPageKraepelin47(state,payload){ state.page_kraepelin47 = payload },
		mutPageKraepelin48(state,payload){ state.page_kraepelin48 = payload },
		mutPageKraepelin49(state,payload){ state.page_kraepelin49 = payload },
		mutPageKraepelin50(state,payload){ state.page_kraepelin50 = payload },
		mutPagePapiInst(state,payload){ state.page_papi_inst = payload },
		mutPagePapiSoal(state,payload){ state.page_papi_soal = payload },
		mutPapiAlert(state,payload){ state.papi_alert = payload },
		mutKraepelinInterval(state,payload){ state.kraepelin_interval = payload },
		mutCFIT1Interval(state,payload){ state.cfit1_interval = payload },
		mutCFIT2Interval(state,payload){ state.cfit2_interval = payload },
		mutCFIT3Interval(state,payload){ state.cfit3_interval = payload },
		mutCFIT4Interval(state,payload){ state.cfit4_interval = payload },
		mutPapiInterval(state,payload){ state.papi_interval = payload }
    },
    actions: {
        setTempProvinsi(context,payload){ context.commit('mutTempProvinsi',payload) },
		setTempKabKota(context,payload){ context.commit('mutTempKabKota',payload) },
		setId(context,payload){ context.commit('mutId',payload) },
		setLokasi(context,payload){ context.commit('mutLokasi',payload) },
		setPageBiodata(context,payload){ context.commit('mutPageBiodata',payload) },
		setPageCfitInst1(context,payload){ context.commit('mutPageCfitInst1',payload) },
		setPageCfitSoal1(context,payload){ context.commit('mutPageCfitSoal1',payload) },
		setPageCfitInst2(context,payload){ context.commit('mutPageCfitInst2',payload) },
		setPageCfitSoal2(context,payload){ context.commit('mutPageCfitSoal2',payload) },
		setPageCfitInst3(context,payload){ context.commit('mutPageCfitInst3',payload) },
		setPageCfitSoal3(context,payload){ context.commit('mutPageCfitSoal3',payload) },
		setPageCfitInst4(context,payload){ context.commit('mutPageCfitInst4',payload) },
		setPageCfitSoal4(context,payload){ context.commit('mutPageCfitSoal4',payload) },
		setPageKraepelinInst(context,payload){ context.commit('mutPageKraepelinInst',payload) },
		setPageKraepelinSim1(context,payload){ context.commit('mutPageKraepelinSim1',payload) },
		setPageKraepelinSim2(context,payload){ context.commit('mutPageKraepelinSim2',payload) },
		setPageKraepelin1(context,payload){ context.commit('mutPageKraepelin1',payload) },
		setPageKraepelin2(context,payload){ context.commit('mutPageKraepelin2',payload) },
		setPageKraepelin3(context,payload){ context.commit('mutPageKraepelin3',payload) },
		setPageKraepelin4(context,payload){ context.commit('mutPageKraepelin4',payload) },
		setPageKraepelin5(context,payload){ context.commit('mutPageKraepelin5',payload) },
		setPageKraepelin6(context,payload){ context.commit('mutPageKraepelin6',payload) },
		setPageKraepelin7(context,payload){ context.commit('mutPageKraepelin7',payload) },
		setPageKraepelin8(context,payload){ context.commit('mutPageKraepelin8',payload) },
		setPageKraepelin9(context,payload){ context.commit('mutPageKraepelin9',payload) },
		setPageKraepelin10(context,payload){ context.commit('mutPageKraepelin10',payload) },
		setPageKraepelin11(context,payload){ context.commit('mutPageKraepelin11',payload) },
		setPageKraepelin12(context,payload){ context.commit('mutPageKraepelin12',payload) },
		setPageKraepelin13(context,payload){ context.commit('mutPageKraepelin13',payload) },
		setPageKraepelin14(context,payload){ context.commit('mutPageKraepelin14',payload) },
		setPageKraepelin15(context,payload){ context.commit('mutPageKraepelin15',payload) },
		setPageKraepelin16(context,payload){ context.commit('mutPageKraepelin16',payload) },
		setPageKraepelin17(context,payload){ context.commit('mutPageKraepelin17',payload) },
		setPageKraepelin18(context,payload){ context.commit('mutPageKraepelin18',payload) },
		setPageKraepelin19(context,payload){ context.commit('mutPageKraepelin19',payload) },
		setPageKraepelin20(context,payload){ context.commit('mutPageKraepelin20',payload) },
		setPageKraepelin21(context,payload){ context.commit('mutPageKraepelin21',payload) },
		setPageKraepelin22(context,payload){ context.commit('mutPageKraepelin22',payload) },
		setPageKraepelin23(context,payload){ context.commit('mutPageKraepelin23',payload) },
		setPageKraepelin24(context,payload){ context.commit('mutPageKraepelin24',payload) },
		setPageKraepelin25(context,payload){ context.commit('mutPageKraepelin25',payload) },
		setPageKraepelin26(context,payload){ context.commit('mutPageKraepelin26',payload) },
		setPageKraepelin27(context,payload){ context.commit('mutPageKraepelin27',payload) },
		setPageKraepelin28(context,payload){ context.commit('mutPageKraepelin28',payload) },
		setPageKraepelin29(context,payload){ context.commit('mutPageKraepelin29',payload) },
		setPageKraepelin30(context,payload){ context.commit('mutPageKraepelin30',payload) },
		setPageKraepelin31(context,payload){ context.commit('mutPageKraepelin31',payload) },
		setPageKraepelin32(context,payload){ context.commit('mutPageKraepelin32',payload) },
		setPageKraepelin33(context,payload){ context.commit('mutPageKraepelin33',payload) },
		setPageKraepelin34(context,payload){ context.commit('mutPageKraepelin34',payload) },
		setPageKraepelin35(context,payload){ context.commit('mutPageKraepelin35',payload) },
		setPageKraepelin36(context,payload){ context.commit('mutPageKraepelin36',payload) },
		setPageKraepelin37(context,payload){ context.commit('mutPageKraepelin37',payload) },
		setPageKraepelin38(context,payload){ context.commit('mutPageKraepelin38',payload) },
		setPageKraepelin39(context,payload){ context.commit('mutPageKraepelin39',payload) },
		setPageKraepelin40(context,payload){ context.commit('mutPageKraepelin40',payload) },
		setPageKraepelin41(context,payload){ context.commit('mutPageKraepelin41',payload) },
		setPageKraepelin42(context,payload){ context.commit('mutPageKraepelin42',payload) },
		setPageKraepelin43(context,payload){ context.commit('mutPageKraepelin43',payload) },
		setPageKraepelin44(context,payload){ context.commit('mutPageKraepelin44',payload) },
		setPageKraepelin45(context,payload){ context.commit('mutPageKraepelin45',payload) },
		setPageKraepelin46(context,payload){ context.commit('mutPageKraepelin46',payload) },
		setPageKraepelin47(context,payload){ context.commit('mutPageKraepelin47',payload) },
		setPageKraepelin48(context,payload){ context.commit('mutPageKraepelin48',payload) },
		setPageKraepelin49(context,payload){ context.commit('mutPageKraepelin49',payload) },
		setPageKraepelin50(context,payload){ context.commit('mutPageKraepelin50',payload) },
		setPagePapiInst(context,payload){ context.commit('mutPagePapiInst',payload) },
		setPagePapiSoal(context,payload){ context.commit('mutPagePapiSoal',payload) },
		setPapiAlert(context,payload){ context.commit('mutPapiAlert',payload) },
		setKraepelinInterval(context,payload){ context.commit('mutKraepelinInterval',payload) },
		setCFIT1Interval(context,payload){ context.commit('mutCFIT1Interval',payload) },
		setCFIT2Interval(context,payload){ context.commit('mutCFIT2Interval',payload) },
		setCFIT3Interval(context,payload){ context.commit('mutCFIT3Interval',payload) },
		setCFIT4Interval(context,payload){ context.commit('mutCFIT4Interval',payload) },
		setPapiInterval(context,payload){ context.commit('mutPapiInterval',payload) }
    },
    modules: {

    },
    plugins: [createPersistedState()]
})
