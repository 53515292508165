<template>
	<div style="background-color: #fff;">
        <div class="container-fluid">
            <main>
                <div class="row g-5" style="max-width:100%">
                    <div class="col-md-7 col-lg-8">
                    	<div class="p-3 mb-4 bg-light rounded-3">
							<div class="container-fluid py-5">
								<img src="/images/CFIT-4.jpg" style="width: 100%;">
							</div>
						</div>
                    </div>
                    <div class="col-md-5 col-lg-4">
                    	<div class="position-sticky" style="top: 2rem;">
	                    	<div class="p-3 mb-4 bg-light rounded-3">
								<div class="container-fluid py-3">
									<h1 class="display-6 fw-bold">TES 1 - SUBTES 4</h1>
									<div class="card bg-danger text-white fw-bold" id="countdown8">
									    <div class="values card-body"></div>
									</div>
									<form @submit.prevent="onSubmit">
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>1.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio11" value="a" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio12" value="b" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio13" value="c" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio14" value="d" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio15" value="e" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>2.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio21" value="a" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio22" value="b" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio23" value="c" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio24" value="d" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio25" value="e" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>3.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio31" value="a" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio32" value="b" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio33" value="c" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio34" value="d" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio35" value="e" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>4.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio41" value="a" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio42" value="b" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio43" value="c" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio44" value="d" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio45" value="e" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>5.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio51" value="a" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio52" value="b" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio53" value="c" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio54" value="d" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio55" value="e" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>6.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio61" value="a" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio62" value="b" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio63" value="c" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio64" value="d" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio65" value="e" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>7.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio71" value="a" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio72" value="b" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio73" value="c" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio74" value="d" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio75" value="e" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>8.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio81" value="a" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio82" value="b" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio83" value="c" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio84" value="d" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio85" value="e" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>9.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio91" value="a" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio92" value="b" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio93" value="c" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio94" value="d" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio95" value="e" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>10.</b>&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio101" value="a" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio102" value="b" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio103" value="c" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio104" value="d" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="inlineRadio105" value="e" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
									</div>
									<button class="w-100 btn btn-primary btn-lg" type="submit" v-if="btn_submit">Kirim</button>
									</form>
								</div>
							</div>
						</div>
                    </div>
                </div>
			</main>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	var { Timer } = require('easytimer.js');
	import Swal from 'sweetalert2'

	export default {
	    data(){
			return {
				disabled : false,
				cfit1 : null,
				cfit2 : null,
				cfit3 : null,
				cfit4 : null,
				cfit5 : null,
				cfit6 : null,
				cfit7 : null,
				cfit8 : null,
				cfit9 : null,
				cfit10 : null,
				btn_submit : this.$store.getters.getDebug
			}
		},
		methods : {
			async onSubmit(){
				try {
					let obj = {
	                    method  : 'post',
	                    url     : this.$store.getters.getServerURL+'cfit/4',
	                    data    : {
						    id : this.$store.getters.getId,
						    cfit1 : this.cfit1,
						    cfit2 : this.cfit2,
						    cfit3 : this.cfit3,
						    cfit4 : this.cfit4,
						    cfit5 : this.cfit5,
						    cfit6 : this.cfit6,
						    cfit7 : this.cfit7,
						    cfit8 : this.cfit8,
						    cfit9 : this.cfit9,
						    cfit10 : this.cfit10
	                    }
	                }
	                console.log('REQ :',obj)
	                var response = await axios(obj)
	                console.log('RES :',response.data)
	                if(response.data.data){
	                	this.$store.dispatch('setPageCfitSoal4',response.data.data[0].id)
						this.$router.replace({ name: 'kraepelininst' })
	                }
				} catch(error) {
					console.error(error)
					Swal.fire({
						title: 'Peringatan',
						text: 'Gagal Kirim Data. Tombol "Kirim" Ditampilkan. Coba Lagi dengan Menekan tombol "Kirim"',
						icon: 'warning',
						confirmButtonText: 'OK'
					})
					this.btn_submit = true
					this.disabled = true
				}
			}
		},
		mounted(){
			console.log('WELCOME CFIT TEST 4')
			var timer = new Timer();
			timer.start({countdown: true, startValues: {seconds: this.$store.getters.getCFIT4Interval}});

			$('#countdown8 .values').html(timer.getTimeValues().toString());

			timer.addEventListener('secondsUpdated', (e)=>{
			    $('#countdown8 .values').html(timer.getTimeValues().toString());
			});

			timer.addEventListener('targetAchieved', (e)=>{
			    if(!this.$store.getters.getDebug) this.onSubmit()
			});
		},
	    unmounted(){
	        console.log('GOOD BYE CFIT TEST 4')
	    }
	}
</script>