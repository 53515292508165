<template>
	<div style="background-color: #fff;">
		<div id="form" class="container">
			<main>
				<div class="row g-5" style="max-width:100%">
					<div class="col-md-7 col-lg-8">
						<h4 class="mb-3">SUB TES 1</h4>
						<form @submit.prevent="onSubmit">
							<table class="table table-striped table-bordered table-sm" border="1">
								<tbody>
									<tr>
										<td></td>
										<th>2</th>
										<td></td>
									</tr>
									<tr>
										<td>No.27</td>
										<td></td>
										<td>
											<input @keyup.down="goprev(27)" type="text" ref="ref27" :disabled="disabled"  v-model="val27">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>7</th>
										<td></td>
									</tr>
									<tr>
										<td>No.26</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(26)" @keyup.down="goprev(26)" ref="ref26" id="ID26" :disabled="disabled"  v-model="val26">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>7</th>
										<td></td>
									</tr>
									<tr>
										<td>No.25</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(25)" @keyup.down="goprev(25)" ref="ref25" id="ID25" :disabled="disabled"  v-model="val25">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>9</th>
										<td></td>
									</tr>
									<tr>
										<td>No.24</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(24)" @keyup.down="goprev(24)" ref="ref24" id="ID24" :disabled="disabled"  v-model="val24">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>7</th>
										<td></td>
									</tr>
									<tr>
										<td>No.23</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(23)" @keyup.down="goprev(23)" ref="ref23" id="ID23" :disabled="disabled"  v-model="val23">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>4</th>
										<td></td>
									</tr>
									<tr>
										<td>No.22</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(22)" @keyup.down="goprev(22)" ref="ref22" id="ID22" :disabled="disabled"  v-model="val22">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>6</th>
										<td></td>
									</tr>
									<tr>
										<td>No.21</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(21)" @keyup.down="goprev(21)" ref="ref21" id="ID21" :disabled="disabled"  v-model="val21">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>6</th>
										<td></td>
									</tr>
									<tr>
										<td>No.20</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(20)" @keyup.down="goprev(20)" ref="ref20" id="ID20" :disabled="disabled"  v-model="val20">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>9</th>
										<td></td>
									</tr>
									<tr>
										<td>No.19</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(19)" @keyup.down="goprev(19)" ref="ref19" id="ID19" :disabled="disabled"  v-model="val19">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>2</th>
										<td></td>
									</tr>
									<tr>
										<td>No.18</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(18)" @keyup.down="goprev(18)" ref="ref18" id="ID18" :disabled="disabled"  v-model="val18">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>2</th>
										<td></td>
									</tr>
									<tr>
										<td>No.17</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(17)" @keyup.down="goprev(17)" ref="ref17" id="ID17" :disabled="disabled"  v-model="val17">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>1</th>
										<td></td>
									</tr>
									<tr>
										<td>No.16</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(16)" @keyup.down="goprev(16)" ref="ref16" id="ID16" :disabled="disabled"  v-model="val16">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>1</th>
										<td></td>
									</tr>
									<tr>
										<td>No.15</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(15)" @keyup.down="goprev(15)" ref="ref15" id="ID15" :disabled="disabled"  v-model="val15">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>8</th>
										<td></td>
									</tr>
									<tr>
										<td>No.14</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(14)" @keyup.down="goprev(14)" ref="ref14" id="ID14" :disabled="disabled"  v-model="val14">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>2</th>
										<td></td>
									</tr>
									<tr>
										<td>No.13</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(13)" @keyup.down="goprev(13)" ref="ref13" id="ID13" :disabled="disabled"  v-model="val13">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>2</th>
										<td></td>
									</tr>
									<tr>
										<td>No.12</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(12)" @keyup.down="goprev(12)" ref="ref12" id="ID12" :disabled="disabled"  v-model="val12">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>6</th>
										<td></td>
									</tr>
									<tr>
										<td>No.11</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(11)" @keyup.down="goprev(11)" ref="ref11" id="ID11" :disabled="disabled"  v-model="val11">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>5</th>
										<td></td>
									</tr>
									<tr>
										<td>No.10</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(10)" @keyup.down="goprev(10)" ref="ref10" id="ID10" :disabled="disabled"  v-model="val10">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>9</th>
										<td></td>
									</tr>
									<tr>
										<td>No.9</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(9)" @keyup.down="goprev(9)"   ref="ref9"  id="ID9" :disabled="disabled"  v-model="val9">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>2</th>
										<td></td>
									</tr>
									<tr>
										<td>No.8</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(8)" @keyup.down="goprev(8)"   ref="ref8"  id="ID8" :disabled="disabled"  v-model="val8">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>9</th>
										<td></td>
									</tr>
									<tr>
										<td>No.7</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(7)" @keyup.down="goprev(7)"   ref="ref7"  id="ID7" :disabled="disabled"  v-model="val7">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>8</th>
										<td></td>
									</tr>
									<tr>
										<td>No.6</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(6)" @keyup.down="goprev(6)"   ref="ref6"  id="ID6" :disabled="disabled"  v-model="val6">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>6</th>
										<td></td>
									</tr>
									<tr>
										<td>No.5</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(5)" @keyup.down="goprev(5)"   ref="ref5"  id="ID5" :disabled="disabled"  v-model="val5">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>4</th>
										<td></td>
									</tr>
									<tr>
										<td>No.4</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(4)" @keyup.down="goprev(4)"   ref="ref4"  id="ID4" :disabled="disabled"  v-model="val4">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>7</th>
										<td></td>
									</tr>
									<tr>
										<td>No.3</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(3)" @keyup.down="goprev(3)"   ref="ref3"  id="ID3" :disabled="disabled"  v-model="val3">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>2</th>
										<td></td>
									</tr>
									<tr>
										<td>No.2</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(2)" @keyup.down="goprev(2)"   ref="ref2"  id="ID2" :disabled="disabled"  v-model="val2">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>9</th>
										<td></td>
									</tr>
									<tr>
										<td>No.1</td>
										<td></td>
										<td>
											<input @keyup.up="gonext(1)" type="text" ref="ref1"  id="ID1" :disabled="disabled"  v-model="val1">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>4</th>
										<td></td>
									</tr>
								</tbody>
							</table>
							<button class="w-100 btn btn-primary btn-lg" type="submit" v-if="btn_submit">Kirim</button>
						</form>
					</div>
					<div class="col-md-5 col-lg-4">
						<div class="position-sticky" style="top: 35vh;">
							<div class="p-3 mb-4 bg-light rounded-3 text-center">
								<h4 class="mb-3">SUB TES 1</h4>
								<div class="container-fluid py-3">
									<div class="card bg-danger text-white fw-bold" id="countdown9">
										<div class="values card-body"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	var { Timer } = require('easytimer.js');
	import Swal from 'sweetalert2'

	export default{
		data(){
			return {
				disabled : false,
				holder : null,
				val1 : null,
				val2 : null,
				val3 : null,
				val4 : null,
				val5 : null,
				val6 : null,
				val7 : null,
				val8 : null,
				val9 : null,
				val10 : null,
				val11 : null,
				val12 : null,
				val13 : null,
				val14 : null,
				val15 : null,
				val16 : null,
				val17 : null,
				val18 : null,
				val19 : null,
				val20 : null,
				val21 : null,
				val22 : null,
				val23 : null,
				val24 : null,
				val25 : null,
				val26 : null,
				val27 : null,
				btn_submit : this.$store.getters.getDebug
			}
		},
		updated(){
			if(isNaN(this.val1)){ this.val1 = null; return }
			if(isNaN(this.val2)){ this.val2 = null; return }
			if(isNaN(this.val3)){ this.val3 = null; return }
			if(isNaN(this.val4)){ this.val4 = null; return }
			if(isNaN(this.val5)){ this.val5 = null; return }
			if(isNaN(this.val6)){ this.val6 = null; return }
			if(isNaN(this.val7)){ this.val7 = null; return }
			if(isNaN(this.val8)){ this.val8 = null; return }
			if(isNaN(this.val9)){ this.val9 = null; return }
			if(isNaN(this.val10)){ this.val10 = null; return }
			if(isNaN(this.val11)){ this.val11 = null; return }
			if(isNaN(this.val12)){ this.val12 = null; return }
			if(isNaN(this.val13)){ this.val13 = null; return }
			if(isNaN(this.val14)){ this.val14 = null; return }
			if(isNaN(this.val15)){ this.val15 = null; return }
			if(isNaN(this.val16)){ this.val16 = null; return }
			if(isNaN(this.val17)){ this.val17 = null; return }
			if(isNaN(this.val18)){ this.val18 = null; return }
			if(isNaN(this.val19)){ this.val19 = null; return }
			if(isNaN(this.val20)){ this.val20 = null; return }
			if(isNaN(this.val21)){ this.val21 = null; return }
			if(isNaN(this.val22)){ this.val22 = null; return }
			if(isNaN(this.val23)){ this.val23 = null; return }
			if(isNaN(this.val24)){ this.val24 = null; return }
			if(isNaN(this.val25)){ this.val25 = null; return }
			if(isNaN(this.val26)){ this.val26 = null; return }
			if(isNaN(this.val27)){ this.val27 = null; return }

			if((this.val1 !== null  && this.val1 !== '')  || (this.val2 !== null  && this.val2 !== '')){
				this.$refs.ref2.focus()
				document.documentElement.scrollTop -= 70
				if((this.val2 !== null  && this.val2 !== '')  || (this.val3 !== null  && this.val3 !== '')){
					this.$refs.ref3.focus()
					document.documentElement.scrollTop -= 70
					if((this.val3 !== null  && this.val3 !== '')  || (this.val4 !== null  && this.val4 !== '')){
						this.$refs.ref4.focus()
						document.documentElement.scrollTop -= 70
						if((this.val4 !== null  && this.val4 !== '')  || (this.val5 !== null  && this.val5 !== '')){
							this.$refs.ref5.focus()
							document.documentElement.scrollTop -= 70
							if((this.val5 !== null  && this.val5 !== '')  || (this.val6 !== null  && this.val6 !== '')){
								this.$refs.ref6.focus()
								document.documentElement.scrollTop -= 70
								if((this.val6 !== null  && this.val6 !== '')  || (this.val7 !== null  && this.val7 !== '')){
									this.$refs.ref7.focus()
									document.documentElement.scrollTop -= 70
									if((this.val7 !== null  && this.val7 !== '')  || (this.val8 !== null  && this.val8 !== '')){
										this.$refs.ref8.focus()
										document.documentElement.scrollTop -= 70
										if((this.val8 !== null  && this.val8 !== '')  || (this.val9 !== null  && this.val9 !== '')){
											this.$refs.ref9.focus()
											document.documentElement.scrollTop -= 70
											if((this.val9 !== null  && this.val9 !== '')  || (this.val10 !== null  && this.val10 !== '')){
												this.$refs.ref10.focus()
												document.documentElement.scrollTop -= 70
												if((this.val10 !== null  && this.val10 !== '')  || (this.val11 !== null  && this.val11 !== '')){
													this.$refs.ref11.focus()
													document.documentElement.scrollTop -= 70
													if((this.val11 !== null  && this.val11 !== '')  || (this.val12 !== null  && this.val12 !== '')){
														this.$refs.ref12.focus()
														document.documentElement.scrollTop -= 70
														if((this.val12 !== null  && this.val12 !== '')  || (this.val13 !== null  && this.val13 !== '')){
															this.$refs.ref13.focus()
															document.documentElement.scrollTop -= 70
															if((this.val13 !== null  && this.val13 !== '')  || (this.val14 !== null  && this.val14 !== '')){
																this.$refs.ref14.focus()
																document.documentElement.scrollTop -= 70
																if((this.val14 !== null  && this.val14 !== '')  || (this.val15 !== null  && this.val15 !== '')){
																	this.$refs.ref15.focus()
																	document.documentElement.scrollTop -= 70
																	if((this.val15 !== null  && this.val15 !== '')  || (this.val16 !== null  && this.val16 !== '')){
																		this.$refs.ref16.focus()
																		document.documentElement.scrollTop -= 70
																		if((this.val16 !== null  && this.val16 !== '')  || (this.val17 !== null  && this.val17 !== '')){
																			this.$refs.ref17.focus()
																			document.documentElement.scrollTop -= 70
																			if((this.val17 !== null  && this.val17 !== '')  || (this.val18 !== null  && this.val18 !== '')){
																				this.$refs.ref18.focus()
																				document.documentElement.scrollTop -= 70
																				if((this.val18 !== null  && this.val18 !== '')  || (this.val19 !== null  && this.val19 !== '')){
																					this.$refs.ref19.focus()
																					document.documentElement.scrollTop -= 70
																					if((this.val19 !== null  && this.val19 !== '')  || (this.val20 !== null  && this.val20 !== '')){
																						this.$refs.ref20.focus()
																						document.documentElement.scrollTop -= 70
																						if((this.val20 !== null  && this.val20 !== '')  || (this.val21 !== null  && this.val21 !== '')){
																							this.$refs.ref21.focus()
																							document.documentElement.scrollTop -= 70
																							if((this.val21 !== null  && this.val21 !== '')  || (this.val22 !== null  && this.val22 !== '')){
																								this.$refs.ref22.focus()
																								if((this.val22 !== null  && this.val22 !== '')  || (this.val23 !== null  && this.val23 !== '')){
																									this.$refs.ref23.focus()
																									document.documentElement.scrollTop -= 70
																									if((this.val23 !== null  && this.val23 !== '')  || (this.val24 !== null  && this.val24 !== '')){
																										this.$refs.ref24.focus()
																										document.documentElement.scrollTop -= 70
																										if((this.val24 !== null  && this.val24 !== '')  || (this.val25 !== null  && this.val25 !== '')){
																											this.$refs.ref25.focus()
																											document.documentElement.scrollTop -= 70
																											if((this.val25 !== null  && this.val25 !== '')  || (this.val26 !== null  && this.val26 !== '')){
																												this.$refs.ref26.focus()
																												document.documentElement.scrollTop -= 70
																												if((this.val26 !== null  && this.val26 !== '')  || (this.val27 !== null  && this.val27 !== '')){
																													this.$refs.ref27.focus()
																													document.documentElement.scrollTop -= 70
																													if(this.val27>10) this.val27 = this.val27%10
																												}
																											}
																										}
																									}
																								}
																							}
																						}
																					}
																				}
																			}
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		},
		methods : {
			async onSubmit(){
				try {
					let obj = {
						method : 'post',
						url : this.$store.getters.getServerURL+'kraepelin',
						data : {
							id : this.$store.getters.getId,
							lajur : 1,
							val1 : this.val1,
							val2 : this.val2,
							val3 : this.val3,
							val4 : this.val4,
							val5 : this.val5,
							val6 : this.val6,
							val7 : this.val7,
							val8 : this.val8,
							val9 : this.val9,
							val10 : this.val10,
							val11 : this.val11,
							val12 : this.val12,
							val13 : this.val13,
							val14 : this.val14,
							val15 : this.val15,
							val16 : this.val16,
							val17 : this.val17,
							val18 : this.val18,
							val19 : this.val19,
							val20 : this.val20,
							val21 : this.val21,
							val22 : this.val22,
							val23 : this.val23,
							val24 : this.val24,
							val25 : this.val25,
							val26 : this.val26,
							val27 : this.val27
						}
					}
					console.log('REQ :',obj)
					var response = await axios(obj)
					console.log('RES :',response.data)
					this.$store.dispatch('setPageKraepelin1',response.data.data[0].id)
					this.$router.replace({ name: 'kraepelin2' })
				} catch(error) {
					console.error(error)
					Swal.fire({
						title: 'Peringatan',
						text: 'Gagal Kirim Data. Tombol "Kirim" Ditampilkan. Coba Lagi dengan Menekan tombol "Kirim"',
						icon: 'warning',
						confirmButtonText: 'OK'
					})
					this.btn_submit = true
					this.disabled = true
				}
			},
			gonext(no){
				let str = 'ref'+(no+1)
				this.$refs[str].focus()
			},
			goprev(no){
				let str = 'ref'+(no-1)
				this.$refs[str].focus()
			}
		},
		mounted(){
			console.log('WELCOME KRAEPELIN 1')
			this.$refs.ref1.focus()
			var timer = new Timer();
			timer.start({countdown: true, startValues: {seconds: this.$store.getters.getKraepelinInterval}});

			$('#countdown9 .values').html(timer.getTimeValues().toString());

			timer.addEventListener('secondsUpdated', (e)=>{
				$('#countdown9 .values').html(timer.getTimeValues().toString());
			});

			timer.addEventListener('targetAchieved', (e)=>{
				if(!this.$store.getters.getDebug) this.onSubmit()
			});
		},
		unmounted(){
			console.log('GOOD BYE KRAEPELIN 1')
		}
	}
</script>