<template>
	<div class="container">
		<header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
			<a @click="goBack" class="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
				<img src="/images/sukun-only.png" alt="PT SUKUN Mc. WARTONO">
			</a>
			<ul class="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0" style="pointer-events: none;">
				<li class="btn btn-sm">
					<a class="nav-link px-2 link-light" style="pointer-events: none;">BIODATA</a>
				</li>
				<li class="btn btn-sm btn-primary">
					<a class="nav-link px-2 link-light" style="pointer-events: none;">TES 1</a>
				</li>
				<li class="btn btn-sm">
					<a class="nav-link px-2 link-light" style="pointer-events: none;">TES 2</a>
				</li>
				<li class="btn btn-sm">
					<a class="nav-link px-2 link-light" style="pointer-events: none;">TES 3</a>
				</li>
				<li class="btn btn-sm">
					<a class="nav-link px-2 link-light" style="pointer-events: none;">SELESAI</a>
				</li>
			</ul>
			<div class="col-md-3 text-end">
			</div>
		</header>
	</div>
</template>

<script>
	import Swal from 'sweetalert2'

	export default{
		methods : {
			goBack(){
				Swal.fire({
					title: 'Anda Yakin Mau Mengakhiri Psikotes?',
					showCancelButton: true,
					confirmButtonText: 'Iya'
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.$router.replace({ name: 'end' })
					}
				})
			}
		}
	}
</script>