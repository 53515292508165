<template>
	<div style="background-color: #fff;">
        <div class="container-flui">
            <main>
                <div class="row g-5" style="max-width:100%">
                    <div class="col-md-7 col-lg-8">
                    	<div class="p-3 mb-4 bg-light rounded-3">
							<div class="container-fluid py-5">
								<img src="/images/CFIT-2a.jpg" style="width: 100%;">
								<img src="/images/CFIT-2b.jpg" style="width: 100%;">
							</div>
						</div>
                    </div>
                    <div class="col-md-5 col-lg-4">
                    	<div class="position-sticky" style="top: 2rem;">
	                    	<div class="p-3 mb-4 bg-light rounded-3">
								<div class="container-fluid py-3">
									<h1 class="display-6 fw-bold">TES 1 - SUBTES 2</h1>
									<div class="card bg-danger text-white fw-bold" id="countdown4">
									    <div class="values card-body"></div>
									</div>
									<form @submit.prevent="onSubmit">
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>1.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox11" value="a" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineCheckbox1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox12" value="b" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineCheckbox2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox13" value="c" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineCheckbox3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox14" value="d" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineCheckbox4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox15" value="e" :disabled="disabled"  v-model="cfit1">
											<label class="form-check-label" for="inlineCheckbox5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>2.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox21" value="a" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineCheckbox1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox22" value="b" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineCheckbox2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox23" value="c" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineCheckbox3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox24" value="d" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineCheckbox4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox25" value="e" :disabled="disabled"  v-model="cfit2">
											<label class="form-check-label" for="inlineCheckbox5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>3.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox31" value="a" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineCheckbox1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox32" value="b" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineCheckbox2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox33" value="c" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineCheckbox3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox34" value="d" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineCheckbox4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox35" value="e" :disabled="disabled"  v-model="cfit3">
											<label class="form-check-label" for="inlineCheckbox5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>4.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox41" value="a" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineCheckbox1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox42" value="b" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineCheckbox2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox43" value="c" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineCheckbox3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox44" value="d" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineCheckbox4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox45" value="e" :disabled="disabled"  v-model="cfit4">
											<label class="form-check-label" for="inlineCheckbox5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>5.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox51" value="a" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineCheckbox1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox52" value="b" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineCheckbox2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox53" value="c" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineCheckbox3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox54" value="d" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineCheckbox4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox55" value="e" :disabled="disabled"  v-model="cfit5">
											<label class="form-check-label" for="inlineCheckbox5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>6.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox61" value="a" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineCheckbox1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox62" value="b" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineCheckbox2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox63" value="c" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineCheckbox3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox64" value="d" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineCheckbox4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox65" value="e" :disabled="disabled"  v-model="cfit6">
											<label class="form-check-label" for="inlineCheckbox5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>7.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox71" value="a" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineCheckbox1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox72" value="b" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineCheckbox2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox73" value="c" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineCheckbox3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox74" value="d" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineCheckbox4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox75" value="e" :disabled="disabled"  v-model="cfit7">
											<label class="form-check-label" for="inlineCheckbox5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>8.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox81" value="a" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineCheckbox1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox82" value="b" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineCheckbox2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox83" value="c" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineCheckbox3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox84" value="d" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineCheckbox4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="inlineCheckbox85" value="e" :disabled="disabled"  v-model="cfit8">
											<label class="form-check-label" for="inlineCheckbox5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>9.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" value="a" id="inlineCheckbox91" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineCheckbox1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" value="b" id="inlineCheckbox92" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineCheckbox2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" value="c" id="inlineCheckbox93" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineCheckbox3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" value="d" id="inlineCheckbox94" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineCheckbox4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" value="e" id="inlineCheckbox95" :disabled="disabled"  v-model="cfit9">
											<label class="form-check-label" for="inlineCheckbox5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>10.</b>&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox101" value="a" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineCheckbox1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox102" value="b" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineCheckbox2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox103" value="c" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineCheckbox3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox104" value="d" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineCheckbox4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox105" value="e" :disabled="disabled"  v-model="cfit10">
											<label class="form-check-label" for="inlineCheckbox5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>11.</b>&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox111" value="a" :disabled="disabled"  v-model="cfit11">
											<label class="form-check-label" for="inlineCheckbox1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox112" value="b" :disabled="disabled"  v-model="cfit11">
											<label class="form-check-label" for="inlineCheckbox2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox113" value="c" :disabled="disabled"  v-model="cfit11">
											<label class="form-check-label" for="inlineCheckbox3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox114" value="d" :disabled="disabled"  v-model="cfit11">
											<label class="form-check-label" for="inlineCheckbox4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox115" value="e" :disabled="disabled"  v-model="cfit11">
											<label class="form-check-label" for="inlineCheckbox5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>12.</b>&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox121" value="a" :disabled="disabled"  v-model="cfit12">
											<label class="form-check-label" for="inlineCheckbox1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox122" value="b" :disabled="disabled"  v-model="cfit12">
											<label class="form-check-label" for="inlineCheckbox2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox123" value="c" :disabled="disabled"  v-model="cfit12">
											<label class="form-check-label" for="inlineCheckbox3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox124" value="d" :disabled="disabled"  v-model="cfit12">
											<label class="form-check-label" for="inlineCheckbox4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox125" value="e" :disabled="disabled"  v-model="cfit12">
											<label class="form-check-label" for="inlineCheckbox5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>13.</b>&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox131" value="a" :disabled="disabled"  v-model="cfit13">
											<label class="form-check-label" for="inlineCheckbox1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox132" value="b" :disabled="disabled"  v-model="cfit13">
											<label class="form-check-label" for="inlineCheckbox2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox133" value="c" :disabled="disabled"  v-model="cfit13">
											<label class="form-check-label" for="inlineCheckbox3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox134" value="d" :disabled="disabled"  v-model="cfit13">
											<label class="form-check-label" for="inlineCheckbox4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox135" value="e" :disabled="disabled"  v-model="cfit13">
											<label class="form-check-label" for="inlineCheckbox5">e</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>14.</b>&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox141" value="a" :disabled="disabled"  v-model="cfit14">
											<label class="form-check-label" for="inlineCheckbox1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox142" value="b" :disabled="disabled"  v-model="cfit14">
											<label class="form-check-label" for="inlineCheckbox2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox143" value="c" :disabled="disabled"  v-model="cfit14">
											<label class="form-check-label" for="inlineCheckbox3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox144" value="d" :disabled="disabled"  v-model="cfit14">
											<label class="form-check-label" for="inlineCheckbox4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox"  id="inlineCheckbox145" value="e" :disabled="disabled"  v-model="cfit14">
											<label class="form-check-label" for="inlineCheckbox5">e</label>
										</div>
									</div>
									<button class="w-100 btn btn-primary btn-lg" type="submit" v-if="btn_submit">Kirim</button>
									</form>
								</div>
							</div>
						</div>
                    </div>
                </div>
			</main>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	var { Timer } = require('easytimer.js');
	import Swal from 'sweetalert2'

	export default{
	    data(){
			return {
				disabled : false,
				cfit1 : [],
				cfit2 : [],
				cfit3 : [],
				cfit4 : [],
				cfit5 : [],
				cfit6 : [],
				cfit7 : [],
				cfit8 : [],
				cfit9 : [],
				cfit10 : [],
				cfit11 : [],
				cfit12 : [],
				cfit13 : [],
				cfit13 : [],
				cfit14 : [],
				btn_submit : this.$store.getters.getDebug
			}
		},
		updated(){
			if (this.cfit1.length > 2) {
				this.cfit1 = []
			}
			if (this.cfit2.length > 2) {
				this.cfit2 = []
			}
			if (this.cfit3.length > 2) {
				this.cfit3 = []
			}
			if (this.cfit4.length > 2) {
				this.cfit4 = []
			}
			if (this.cfit5.length > 2) {
				this.cfit5 = []
			}
			if (this.cfit6.length > 2) {
				this.cfit6 = []
			}
			if (this.cfit7.length > 2) {
				this.cfit7 = []
			}
			if (this.cfit8.length > 2) {
				this.cfit8 = []
			}
			if (this.cfit9.length > 2) {
				this.cfit9 = []
			}
			if (this.cfit10.length > 2) {
				this.cfit10 = []
			}
			if (this.cfit11.length > 2) {
				this.cfit11 = []
			}
			if (this.cfit12.length > 2) {
				this.cfit12 = []
			}
			if (this.cfit13.length > 2) {
				this.cfit13 = []
			}
			if (this.cfit14.length > 2) {
				this.cfit14 = []
			}
		},
		methods : {
			async onSubmit(){
				try {
					let obj = {
	                    method  : 'post',
	                    url     : this.$store.getters.getServerURL+'cfit/2',
	                    data    : {
						    id : this.$store.getters.getId,
						    cfit11 : this.cfit1[0]?this.cfit1[0]:null,
						    cfit12 : this.cfit1[1]?this.cfit1[1]:null,
						    cfit21 : this.cfit2[0]?this.cfit2[0]:null,
						    cfit22 : this.cfit2[1]?this.cfit2[1]:null,
						    cfit31 : this.cfit3[0]?this.cfit3[0]:null,
						    cfit32 : this.cfit3[1]?this.cfit3[1]:null,
						    cfit41 : this.cfit4[0]?this.cfit4[0]:null,
						    cfit42 : this.cfit4[1]?this.cfit4[1]:null,
						    cfit51 : this.cfit5[0]?this.cfit5[0]:null,
						    cfit52 : this.cfit5[1]?this.cfit5[1]:null,
						    cfit61 : this.cfit6[0]?this.cfit6[0]:null,
						    cfit62 : this.cfit6[1]?this.cfit6[1]:null,
						    cfit71 : this.cfit7[0]?this.cfit7[0]:null,
						    cfit72 : this.cfit7[1]?this.cfit7[1]:null,
						    cfit81 : this.cfit8[0]?this.cfit8[0]:null,
						    cfit82 : this.cfit8[1]?this.cfit8[1]:null,
						    cfit91 : this.cfit9[0]?this.cfit9[0]:null,
						    cfit92 : this.cfit9[1]?this.cfit9[1]:null,
						    cfit101 : this.cfit10[0]?this.cfit10[0]:null,
						    cfit102 : this.cfit10[1]?this.cfit10[1]:null,
						    cfit111 : this.cfit11[0]?this.cfit11[0]:null,
						    cfit112 : this.cfit11[1]?this.cfit11[1]:null,
						    cfit121 : this.cfit12[0]?this.cfit12[0]:null,
						    cfit122 : this.cfit12[1]?this.cfit12[1]:null,
						    cfit131 : this.cfit13[0]?this.cfit13[0]:null,
						    cfit132 : this.cfit13[1]?this.cfit13[1]:null,
						    cfit141 : this.cfit14[0]?this.cfit14[0]:null,
						    cfit142 : this.cfit14[1]?this.cfit14[1]:null
	                    }
	                }
	                console.log('REQ :',obj)
	                var response = await axios(obj)
					console.log('RES :',response.data)
	                if(response.data.data){
	                	this.$store.dispatch('setPageCfitSoal2',response.data.data[0].id)
						this.$router.replace({ name: 'cfitinst3' })
	                }
				} catch(error) {
					console.error(error)
					Swal.fire({
						title: 'Peringatan',
						text: 'Gagal Kirim Data. Tombol "Kirim" Ditampilkan. Coba Lagi dengan Menekan tombol "Kirim"',
						icon: 'warning',
						confirmButtonText: 'OK'
					})
					this.btn_submit = true
					this.disabled = true
				}
			}
		},
		mounted(){
			console.log('WELCOME CFIT TEST 2')
			var timer = new Timer();
			timer.start({countdown: true, startValues: {seconds: this.$store.getters.getCFIT2Interval}});

			$('#countdown4 .values').html(timer.getTimeValues().toString());

			timer.addEventListener('secondsUpdated', (e)=>{
			    $('#countdown4 .values').html(timer.getTimeValues().toString());
			});

			timer.addEventListener('targetAchieved', (e)=>{
			    if(!this.$store.getters.getDebug) this.onSubmit()
			});
		},
	    unmounted(){
	        console.log('GOOD BYE CFIT TEST 2')
	    }
	}
</script>