<template>
	<div style="background-color: #fff;">
		<div id="form" class="container">
			<main>
				<div class="row g-5" style="max-width:100%">
					<div class="col-md-7 col-lg-8">
						<h4 class="mb-3">TES 2 SIMULASI 2</h4>
						<form @submit.prevent="onSubmit">
							<table class="table table-striped table-bordered table-sm" border="1">
								<tbody>
									<tr>
										<td></td>
										<th>1</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default27, 'table-warning' : warning27, 'table-success' : success27, 'table-danger' : danger27 }">
										<td>No.27</td>
										<td></td>
										<td>
											<input @keyup.down="goprev(27)" type="text" ref="ref27" v-model="val27" />
										</td>
									</tr>
									<tr>
										<td></td>
										<th>9</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default26, 'table-warning' : warning26, 'table-success' : success26, 'table-danger' : danger26 }">
										<td>No.26</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(26)" @keyup.down="goprev(26)" ref="ref26" v-model="val26">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>4</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default25, 'table-warning' : warning25, 'table-success' : success25, 'table-danger' : danger25 }">
										<td>No.25</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(25)" @keyup.down="goprev(25)" ref="ref25" v-model="val25">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>6</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default24, 'table-warning' : warning24, 'table-success' : success24, 'table-danger' : danger25 }">
										<td>No.24</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(24)" @keyup.down="goprev(24)" ref="ref24" v-model="val24">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>9</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default23, 'table-warning' : warning23, 'table-success' : success23, 'table-danger' : danger23 }">
										<td>No.23</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(23)" @keyup.down="goprev(23)" ref="ref23" v-model="val23">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>3</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default22, 'table-warning' : warning22, 'table-success' : success22, 'table-danger' : danger22 }">
										<td>No.22</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(22)" @keyup.down="goprev(22)" ref="ref22" v-model="val22">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>8</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default21, 'table-warning' : warning21, 'table-success' : success21, 'table-danger' : danger21 }">
										<td>No.21</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(21)" @keyup.down="goprev(21)" ref="ref21" v-model="val21">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>8</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default20, 'table-warning' : warning20, 'table-success' : success20, 'table-danger' : danger20 }">
										<td>No.20</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(20)" @keyup.down="goprev(20)" ref="ref20" v-model="val20">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>9</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default19, 'table-warning' : warning19, 'table-success' : success19, 'table-danger' : danger19 }">
										<td>No.19</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(19)" @keyup.down="goprev(19)" ref="ref19" v-model="val19">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>3</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default18, 'table-warning' : warning18, 'table-success' : success18, 'table-danger' : danger18 }">
										<td>No.18</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(18)" @keyup.down="goprev(18)" ref="ref18" v-model="val18">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>7</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default17, 'table-warning' : warning17, 'table-success' : success17, 'table-danger' : danger17 }">
										<td>No.17</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(17)" @keyup.down="goprev(17)" ref="ref17" v-model="val17">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>6</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default16, 'table-warning' : warning16, 'table-success' : success16, 'table-danger' : danger16 }">
										<td>No.16</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(16)" @keyup.down="goprev(16)" ref="ref16" v-model="val16">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>2</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default15, 'table-warning' : warning15, 'table-success' : success15, 'table-danger' : danger15 }">
										<td>No.15</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(15)" @keyup.down="goprev(15)" ref="ref15" v-model="val15">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>5</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default14, 'table-warning' : warning14, 'table-success' : success14, 'table-danger' : danger14 }">
										<td>No.14</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(14)" @keyup.down="goprev(14)" ref="ref14" v-model="val14">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>6</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default13, 'table-warning' : warning13, 'table-success' : success13, 'table-danger' : danger13 }">
										<td>No.13</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(13)" @keyup.down="goprev(13)" ref="ref13" v-model="val13">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>4</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default12, 'table-warning' : warning12, 'table-success' : success12, 'table-danger' : danger12 }">
										<td>No.12</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(12)" @keyup.down="goprev(12)" ref="ref12" v-model="val12">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>3</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default11, 'table-warning' : warning11, 'table-success' : success11, 'table-danger' : danger11 }">
										<td>No.11</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(11)" @keyup.down="goprev(11)" ref="ref11" v-model="val11">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>3</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default10, 'table-warning' : warning10, 'table-success' : success10, 'table-danger' : danger10 }">
										<td>No.10</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(10)" @keyup.down="goprev(10)" ref="ref10" v-model="val10">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>3</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default9, 'table-warning' : warning9, 'table-success' : success9, 'table-danger' : danger9 }">
										<td>No.9</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(9)" @keyup.down="goprev(9)"  ref="ref9" v-model="val9">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>8</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default8, 'table-warning' : warning8, 'table-success' : success8, 'table-danger' : danger8 }">
										<td>No.8</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(8)" @keyup.down="goprev(8)"  ref="ref8" v-model="val8">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>6</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default7, 'table-warning' : warning7, 'table-success' : success7, 'table-danger' : danger7 }">
										<td>No.7</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(7)" @keyup.down="goprev(7)"  ref="ref7" v-model="val7">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>3</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default6, 'table-warning' : warning6, 'table-success' : success6, 'table-danger' : danger6 }">
										<td>No.6</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(6)" @keyup.down="goprev(6)"  ref="ref6" v-model="val6">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>2</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default5, 'table-warning' : warning5, 'table-success' : success5, 'table-danger' : danger5 }">
										<td>No.5</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(5)" @keyup.down="goprev(5)"  ref="ref5" v-model="val5">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>7</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default4, 'table-warning' : warning4, 'table-success' : success4, 'table-danger' : danger4 }">
										<td>No.4</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(4)" @keyup.down="goprev(4)"  ref="ref4" v-model="val4">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>6</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default3, 'table-warning' : warning3, 'table-success' : success3, 'table-danger' : danger3 }">
										<td>No.3</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(3)" @keyup.down="goprev(3)"  ref="ref3" v-model="val3">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>9</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default2, 'table-warning' : warning2, 'table-success' : success2, 'table-danger' : danger2 }">
										<td>No.2</td>
										<td></td>
										<td>
											<input type="text" @keyup.up="gonext(2)" @keyup.down="goprev(2)"  ref="ref2" v-model="val2">
										</td>
									</tr>
									<tr>
										<td></td>
										<th>6</th>
										<td></td>
									</tr>
									<tr :class="{ 'table-default' : default1, 'table-warning' : warning1, 'table-success' : success1, 'table-danger' : danger1 }">
										<td>No.1</td>
										<td></td>
										<td>
											<input @keyup.up="gonext(1)" type="text" ref="ref1" v-model="val1" />
										</td>
									</tr>
									<tr>
										<td></td>
										<th>1</th>
										<td></td>
									</tr>
								</tbody>
							</table>
							<button class="w-100 btn btn-primary btn-lg" type="submit">Kirim</button>
						</form>
					</div>
					<div class="col-md-5 col-lg-4">
						<div class="position-sticky" style="top: 35vh;">
							<div class="p-3 mb-4 bg-light rounded-3 text-center">
								<h4 class="mb-3">TES 2 SIMULASI 2</h4>
								<div class="container-fluid py-3">
									<div class="card bg-danger text-white fw-bold" id="countdown63">
										<div class="values card-body"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	var { Timer } = require('easytimer.js');
	import Swal from 'sweetalert2'
	export default{
		data(){
			return {
				val1 : null,
				val2 : null,
				val3 : null,
				val4 : null,
				val5 : null,
				val6 : null,
				val7 : null,
				val8 : null,
				val9 : null,
				val10 : null,
				val11 : null,
				val12 : null,
				val13 : null,
				val14 : null,
				val15 : null,
				val16 : null,
				val17 : null,
				val18 : null,
				val19 : null,
				val20 : null,
				val21 : null,
				val22 : null,
				val23 : null,
				val24 : null,
				val25 : null,
				val26 : null,
				val27 : null,

				default27 : false,
				warning27 : false,
				success27 : false,
				danger27 : false,

				default26 : false,
				warning26 : false,
				success26 : false,
				danger26 : false,

				default25 : false,
				warning25 : false,
				success25 : false,
				danger25 : false,

				default24 : false,
				warning24 : false,
				success24 : false,
				danger24 : false,

				default23 : false,
				warning23 : false,
				success23 : false,
				danger23 : false,

				default22 : false,
				warning22 : false,
				success22 : false,
				danger22 : false,

				default21 : false,
				warning21 : false,
				success21 : false,
				danger21 : false,

				default20 : false,
				warning20 : false,
				success20 : false,
				danger20 : false,

				default19 : false,
				warning19 : false,
				success19 : false,
				danger19 : false,

				default18 : false,
				warning18 : false,
				success18 : false,
				danger18 : false,

				default17 : false,
				warning17 : false,
				success17 : false,
				danger17 : false,

				default16 : false,
				warning16 : false,
				success16 : false,
				danger16 : false,

				default15 : false,
				warning15 : false,
				success15 : false,
				danger15 : false,

				default14 : false,
				warning14 : false,
				success14 : false,
				danger14 : false,

				default13 : false,
				warning13 : false,
				success13 : false,
				danger13 : false,

				default12 : false,
				warning12 : false,
				success12 : false,
				danger12 : false,

				default11 : false,
				warning11 : false,
				success11 : false,
				danger11 : false,

				default10 : false,
				warning10 : false,
				success10 : false,
				danger10 : false,

				default9 : false,
				warning9 : false,
				success9 : false,
				danger9 : false,

				default8 : false,
				warning8 : false,
				success8 : false,
				danger8 : false,

				default7 : false,
				warning7 : false,
				success7 : false,
				danger7 : false,

				default6 : false,
				warning6 : false,
				success6 : false,
				danger6 : false,

				default5 : false,
				warning5 : false,
				success5 : false,
				danger5 : false,

				default4 : false,
				warning4 : false,
				success4 : false,
				danger4 : false,

				default3 : false,
				warning3 : false,
				success3 : false,
				danger3 : false,

				default2 : false,
				warning2 : false,
				success2 : false,
				danger2 : false,

				default1 : false,
				warning1 : false,
				success1 : false,
				danger1 : false
			}
		},
		updated(){
			if(isNaN(this.val1)){ this.val1 = null; return }
			if(isNaN(this.val2)){ this.val2 = null; return }
			if(isNaN(this.val3)){ this.val3 = null; return }
			if(isNaN(this.val4)){ this.val4 = null; return }
			if(isNaN(this.val5)){ this.val5 = null; return }
			if(isNaN(this.val6)){ this.val6 = null; return }
			if(isNaN(this.val7)){ this.val7 = null; return }
			if(isNaN(this.val8)){ this.val8 = null; return }
			if(isNaN(this.val9)){ this.val9 = null; return }
			if(isNaN(this.val10)){ this.val10 = null; return }
			if(isNaN(this.val11)){ this.val11 = null; return }
			if(isNaN(this.val12)){ this.val12 = null; return }
			if(isNaN(this.val13)){ this.val13 = null; return }
			if(isNaN(this.val14)){ this.val14 = null; return }
			if(isNaN(this.val15)){ this.val15 = null; return }
			if(isNaN(this.val16)){ this.val16 = null; return }
			if(isNaN(this.val17)){ this.val17 = null; return }
			if(isNaN(this.val18)){ this.val18 = null; return }
			if(isNaN(this.val19)){ this.val19 = null; return }
			if(isNaN(this.val20)){ this.val20 = null; return }
			if(isNaN(this.val21)){ this.val21 = null; return }
			if(isNaN(this.val22)){ this.val22 = null; return }
			if(isNaN(this.val23)){ this.val23 = null; return }
			if(isNaN(this.val24)){ this.val24 = null; return }
			if(isNaN(this.val25)){ this.val25 = null; return }
			if(isNaN(this.val26)){ this.val26 = null; return }
			if(isNaN(this.val27)){ this.val27 = null; return }

			if((this.val1 !== null  && this.val1 !== '') || (this.val2 !== null  && this.val2 !== '')){
				this.$refs.ref2.focus()
				if(this.val1==7){
					this.default1 = false
					this.warning1 = false
					this.success1 = true
					this.danger1 = false
				} else {
					if(this.val1){
						this.default1 = false
						this.warning1 = false
						this.success1 = false
						this.danger1 = true
					} else {
						this.default1 = false
						this.warning1 = true
						this.success1 = false
						this.danger1 = false
					}
				}
				if((this.val2 !== null  && this.val2 !== '') || (this.val3 !== null && this.val3 !== '')){
					this.$refs.ref3.focus()
					if(this.val2==5){
						this.default2 = false
						this.warning2 = false
						this.success2 = true
						this.danger2 = false
					} else {
						if(this.val2){
							this.default2 = false
							this.warning2 = false
							this.success2 = false
							this.danger2 = true
						} else {
							this.default2 = false
							this.warning2 = true
							this.success2 = false
							this.danger2 = false
						}
					}
					if((this.val3 !== null  && this.val3 !== '')  || (this.val4 !== null  && this.val4 !== '')){
						this.$refs.ref4.focus()
						if(this.val3==5){
							this.default3 = false
							this.warning3 = false
							this.success3 = true
							this.danger3 = false
						} else {
							if(this.val3){
								this.default3 = false
								this.warning3 = false
								this.success3 = false
								this.danger3 = true
							} else {
								this.default3 = false
								this.warning3 = true
								this.success3 = false
								this.danger3 = false
							}
						}
						if((this.val4 !== null  && this.val4 !== '')  || (this.val5 !== null  && this.val5 !== '')){
							this.$refs.ref5.focus()
							if(this.val4==3){
								this.default4 = false
								this.warning4 = false
								this.success4 = true
								this.danger4 = false
							} else {
								if(this.val4){
									this.default4 = false
									this.warning4 = false
									this.success4 = false
									this.danger4 = true
								} else {
									this.default4 = false
									this.warning4 = true
									this.success4 = false
									this.danger4 = false
								}
							}
							if((this.val5 !== null  && this.val5 !== '') || (this.val6 !== null  && this.val6 !== '')){
								this.$refs.ref6.focus()
								if(this.val5==9){
									this.default5 = false
									this.warning5 = false
									this.success5 = true
									this.danger5 = false
								} else {
									if(this.val5){
										this.default5 = false
										this.warning5 = false
										this.success5 = false
										this.danger5 = true
									} else {
										this.default5 = false
										this.warning5 = true
										this.success5 = false
										this.danger5 = false
									}
								}
								if((this.val6 !== null  && this.val6 !== '')  || (this.val7 !== null  && this.val7 !== '')){
									this.$refs.ref7.focus()
									if(this.val6==5){
										this.default6 = false
										this.warning6 = false
										this.success6 = true
										this.danger6 = false
									} else {
										if(this.val6){
											this.default6 = false
											this.warning6 = false
											this.success6 = false
											this.danger6 = true
										} else {
											this.default6 = false
											this.warning6 = true
											this.success6 = false
											this.danger6 = false
										}
									}
									if((this.val7 !== null  && this.val7 !== '')  || (this.val8 !== null  && this.val8 !== '')){
										this.$refs.ref8.focus()
										if(this.val7==9){
											this.default7 = false
											this.warning7 = false
											this.success7 = true
											this.danger7 = false
										} else {
											if(this.val7){
												this.default7 = false
												this.warning7 = false
												this.success7 = false
												this.danger7 = true
											} else {
												this.default7 = false
												this.warning7 = true
												this.success7 = false
												this.danger7 = false
											}
										}
										if((this.val8 !== null  && this.val8 !== '')  || (this.val9 !== null  && this.val9 !== '')){
											this.$refs.ref9.focus()
											if(this.val8==4){
												this.default8 = false
												this.warning8 = false
												this.success8 = true
												this.danger8 = false
											} else {
												if(this.val8){
													this.default8 = false
													this.warning8 = false
													this.success8 = false
													this.danger8 = true
												} else {
													this.default8 = false
													this.warning8 = true
													this.success8 = false
													this.danger8 = false
												}
											}
											if((this.val9 !== null  && this.val9 !== '')  || (this.val10 !== null  && this.val10 !== '')){
												this.$refs.ref10.focus()
												if(this.val9==1){
													this.default9 = false
													this.warning9 = false
													this.success9 = true
													this.danger9 = false
												} else {
													if(this.val9){
														this.default9 = false
														this.warning9 = false
														this.success9 = false
														this.danger9 = true
													} else {
														this.default9 = false
														this.warning9 = true
														this.success9 = false
														this.danger9 = false
													}
												}
												if((this.val10 !== null  && this.val10 !== '')  || (this.val11 !== null  && this.val11 !== '')){
													this.$refs.ref11.focus()
													if(this.val10==6){
														this.default10 = false
														this.warning10 = false
														this.success10 = true
														this.danger10 = false
													} else {
														if(this.val10){
															this.default10 = false
															this.warning10 = false
															this.success10 = false
															this.danger10 = true
														} else {
															this.default10 = false
															this.warning10 = true
															this.success10 = false
															this.danger10 = false
														}
													}
													if((this.val11 !== null  && this.val11 !== '')  || (this.val12 !== null  && this.val12 !== '')){
														this.$refs.ref12.focus()
														if(this.val11==6){
															this.default11 = false
															this.warning11 = false
															this.success11 = true
															this.danger11 = false
														} else {
															if(this.val11){
																this.default11 = false
																this.warning11 = false
																this.success11 = false
																this.danger11 = true
															} else {
																this.default11 = false
																this.warning11 = true
																this.success11 = false
																this.danger11 = false
															}
														}
														if((this.val12 !== null  && this.val12 !== '')  || (this.val13 !== null  && this.val13 !== '')){
															this.$refs.ref13.focus()
															if(this.val12==7){
																this.default12 = false
																this.warning12 = false
																this.success12 = true
																this.danger12 = false
															} else {
																if(this.val12){
																	this.default12 = false
																	this.warning12 = false
																	this.success12 = false
																	this.danger12 = true
																} else {
																	this.default12 = false
																	this.warning12 = true
																	this.success12 = false
																	this.danger12 = false
																}
															}
															if((this.val13 !== null  && this.val13 !== '')  || (this.val14 !== null  && this.val14 !== '')){
																this.$refs.ref14.focus()
																if(this.val13==0){
																	this.default13 = false
																	this.warning13 = false
																	this.success13 = true
																	this.danger13 = false
																} else {
																	if(this.val13){
																		this.default13 = false
																		this.warning13 = false
																		this.success13 = false
																		this.danger13 = true
																	} else {
																		this.default13 = false
																		this.warning13 = true
																		this.success13 = false
																		this.danger13 = false
																	}
																}
																if((this.val14 !== null  && this.val14 !== '')  || (this.val15 !== null  && this.val15 !== '')){
																	this.$refs.ref15.focus()
																	if(this.val14==1){
																		this.default14 = false
																		this.warning14 = false
																		this.success14 = true
																		this.danger14 = false
																	} else {
																		if(this.val14){
																			this.default14 = false
																			this.warning14 = false
																			this.success14 = false
																			this.danger14 = true
																		} else {
																			this.default14 = false
																			this.warning14 = true
																			this.success14 = false
																			this.danger14 = false
																		}
																	}
																	if((this.val15 !== null  && this.val15 !== '')  || (this.val16 !== null  && this.val16 !== '')){
																		this.$refs.ref16.focus()
																		if(this.val15==7){
																			this.default15 = false
																			this.warning15 = false
																			this.success15 = true
																			this.danger15 = false
																		} else {
																			if(this.val15){
																				this.default15 = false
																				this.warning15 = false
																				this.success15 = false
																				this.danger15 = true
																			} else {
																				this.default15 = false
																				this.warning15 = true
																				this.success15 = false
																				this.danger15 = false
																			}
																		}
																		if((this.val16 !== null  && this.val16 !== '')  || (this.val17 !== null  && this.val17 !== '')){
																			this.$refs.ref17.focus()
																			if(this.val16==8){
																				this.default16 = false
																				this.warning16 = false
																				this.success16 = true
																				this.danger16 = false
																			} else {
																				if(this.val16){
																					this.default16 = false
																					this.warning16 = false
																					this.success16 = false
																					this.danger16 = true
																				} else {
																					this.default16 = false
																					this.warning16 = true
																					this.success16 = false
																					this.danger16 = false
																				}
																			}
																			if((this.val17 !== null  && this.val17 !== '')  || (this.val18 !== null  && this.val18 !== '')){
																				this.$refs.ref18.focus()
																				if(this.val17==3){
																					this.default17 = false
																					this.warning17 = false
																					this.success17 = true
																					this.danger17 = false
																				} else {
																					if(this.val17){
																						this.default17 = false
																						this.warning17 = false
																						this.success17 = false
																						this.danger17 = true
																					} else {
																						this.default17 = false
																						this.warning17 = true
																						this.success17 = false
																						this.danger17 = false
																					}
																				}
																				if((this.val18 !== null  && this.val18 !== '')  || (this.val19 !== null  && this.val19 !== '')){
																					this.$refs.ref19.focus()
																					if(this.val18==0){
																						this.default18 = false
																						this.warning18 = false
																						this.success18 = true
																						this.danger18 = false
																					} else {
																						if(this.val18){
																							this.default18 = false
																							this.warning18 = false
																							this.success18 = false
																							this.danger18 = true
																						} else {
																							this.default18 = false
																							this.warning18 = true
																							this.success18 = false
																							this.danger18 = false
																						}
																					}
																					if((this.val19 !== null  && this.val19 !== '')  || (this.val20 !== null  && this.val20 !== '')){
																						this.$refs.ref20.focus()
																						if(this.val19==2){
																							this.default19 = false
																							this.warning19 = false
																							this.success19 = true
																							this.danger19 = false
																						} else {
																							if(this.val19){
																								this.default19 = false
																								this.warning19 = false
																								this.success19 = false
																								this.danger19 = true
																							} else {
																								this.default19 = false
																								this.warning19 = true
																								this.success19 = false
																								this.danger19 = false
																							}
																						}
																						if((this.val20 !== null  && this.val20 !== '')  || (this.val21 !== null  && this.val21 !== '')){
																							this.$refs.ref21.focus()
																							if(this.val20==7){
																								this.default20 = false
																								this.warning20 = false
																								this.success20 = true
																								this.danger20 = false
																							} else {
																								if(this.val20){
																									this.default20 = false
																									this.warning20 = false
																									this.success20 = false
																									this.danger20 = true
																								} else {
																									this.default20 = false
																									this.warning20 = true
																									this.success20 = false
																									this.danger20 = false
																								}
																							}
																							if((this.val21 !== null  && this.val21 !== '')  || (this.val22 !== null  && this.val22 !== '')){
																								this.$refs.ref22.focus()
																								if(this.val21==6){
																									this.default21 = false
																									this.warning21 = false
																									this.success21 = true
																									this.danger21 = false
																								} else {
																									if(this.val21){
																										this.default21 = false
																										this.warning21 = false
																										this.success21 = false
																										this.danger21 = true
																									} else {
																										this.default21 = false
																										this.warning21 = true
																										this.success21 = false
																										this.danger21 = false
																									}
																								}
																								if((this.val22 !== null  && this.val22 !== '')  || (this.val23 !== null  && this.val23 !== '')){
																									this.$refs.ref23.focus()
																									if(this.val22==1){
																										this.default22 = false
																										this.warning22 = false
																										this.success22 = true
																										this.danger22 = false
																									} else {
																										if(this.val22){
																											this.default22 = false
																											this.warning22 = false
																											this.success22 = false
																											this.danger22 = true
																										} else {
																											this.default22 = false
																											this.warning22 = true
																											this.success22 = false
																											this.danger22 = false
																										}
																									}
																									if((this.val23 !== null  && this.val23 !== '')  || (this.val24 !== null  && this.val24 !== '')){
																										this.$refs.ref24.focus()
																										if(this.val23==2){
																											this.default23 = false
																											this.warning23 = false
																											this.success23 = true
																											this.danger23 = false
																										} else {
																											if(this.val23){
																												this.default23 = false
																												this.warning23 = false
																												this.success23 = false
																												this.danger23 = true
																											} else {
																												this.default23 = false
																												this.warning23 = true
																												this.success23 = false
																												this.danger23 = false
																											}
																										}
																										if((this.val24 !== null  && this.val24 !== '')  || (this.val25 !== null  && this.val25 !== '')){
																											this.$refs.ref25.focus()
																											if(this.val24==5){
																												this.default24 = false
																												this.warning24 = false
																												this.success24 = true
																												this.danger24 = false
																											} else {
																												if(this.val24){
																													this.default24 = false
																													this.warning24 = false
																													this.success24 = false
																													this.danger24 = true
																												} else {
																													this.default24 = false
																													this.warning24 = true
																													this.success24 = false
																													this.danger24 = false
																												}
																											}
																										}
																										if((this.val25 !== null  && this.val25 !== '')  || (this.val26 !== null  && this.val26 !== '')){
																											this.$refs.ref26.focus()
																											if(this.val25==0){
																												this.default25 = false
																												this.warning25 = false
																												this.success25 = true
																												this.danger25 = false
																											} else {
																												if(this.val25){
																													this.default25 = false
																													this.warning25 = false
																													this.success25 = false
																													this.danger25 = true
																												} else {
																													this.default25 = false
																													this.warning25 = true
																													this.success25 = false
																													this.danger25 = false
																												}
																											}
																											if((this.val26 !== null  && this.val26 !== '')  || (this.val27 !== null  && this.val27 !== '')){
																												this.$refs.ref27.focus()
																												if(this.val26==3){
																													this.default26 = false
																													this.warning26 = false
																													this.success26 = true
																													this.danger26 = false
																												} else {
																													if(this.val26){
																														this.default26 = false
																														this.warning26 = false
																														this.success26 = false
																														this.danger26 = true
																													} else {
																														this.default26 = false
																														this.warning26 = true
																														this.success26 = false
																														this.danger26 = false
																													}
																												}
																												if(this.val27 !== null  && this.val27 !== ''){
																													if(this.val27>10) this.val27 = this.val27%10
																													if(this.val27==0){
																														this.default27 = false
																														this.warning27 = false
																														this.success27 = true
																														this.danger27 = false
																													} else {
																														if(this.val27){
																															this.default27 = false
																															this.warning27 = false
																															this.success27 = false
																															this.danger27 = true
																														} else {
																															this.default27 = false
																															this.warning27 = true
																															this.success27 = false
																															this.danger27 = false
																														}
																													}
																												}
																											}
																										}
																									}
																								}
																							}
																						}
																					}
																				}
																			}
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		},
		methods : {
			onSubmit(){
				let timerInterval
				Swal.fire({
					icon: 'info',
					title: 'Pemberitahuan',
					html: 'Simulasi / Latihan tes telah dilaksanakan, persiapkan diri anda untuk melaksanakan tesnya!.',
					timer: 10000,
					timerProgressBar: true,
					didOpen: () => {
						timerInterval = setInterval(() => { }, 1000)
					},
					willClose: () => {
						clearInterval(timerInterval)
					}
				}).then(async (result) => {
					if (result.dismiss === Swal.DismissReason.timer || result.isConfirmed || result.dismiss) {
						try {
							var response = await axios.get(this.$store.getters.getServerURL+'par/kraepelin-petunjuk/'+this.$store.getters.getLokasi)
							if(response.data.data){
								await axios.get(this.$store.getters.getServerURL+'page/kraepelin-simulasi2/'+this.$store.getters.getId)
								this.$store.dispatch('setPageKraepelinSim2',true)
								this.$router.replace({ name: 'kraepelin1' })
							} else {
								Swal.fire({
									title: 'Peringatan',
									text: response.data.error_desc,
									icon: 'warning',
									confirmButtonText: 'OK'
								})
							}
						} catch(error) {
							console.error(error)
						}
					}
				})
			},
			gonext(no){
				let str = 'ref'+(no+1)
				this.$refs[str].focus()
			},
			goprev(no){
				let str = 'ref'+(no-1)
				this.$refs[str].focus()
			}
		},
		mounted(){
			console.log('WELCOME KRAEPELIN SIMULASI 2')
			this.$refs.ref1.focus()
			var timer = new Timer();
			timer.start({countdown: true, startValues: {seconds: 30}});

			$('#countdown63 .values').html(timer.getTimeValues().toString());

			timer.addEventListener('secondsUpdated', (e)=>{
				$('#countdown63 .values').html(timer.getTimeValues().toString());
			});

			timer.addEventListener('targetAchieved', (e)=>{
				this.onSubmit()
			});
		},
		unmounted(){
			console.log('GOOD BYE KRAEPELIN SIMULASI 2')
		}
	}
</script>