<template>
    <div class="parallax relative">
        <div class="bg-gradient-to-b from-blue-600 to-blue-400 aspect-video">
            <span class="absolute top-10 left-10 w-56"><img src="/sukun-only.png" alt="PT SUKUN Mc. WARTONO Logo"></span>
            <span value="4" class="move absolute" style="top:-6%;"><img src="//static.domainesia.com/assets/slots/webcdn/cpanel/img/landing/bg-milky-way.svg" alt="DomaiNesia Milky Way"></span>
            <span value="2" class="move absolute" style="top:30%;"><img src="//static.domainesia.com/assets/slots/webcdn/cpanel/img/landing/bg-hills-v2.svg" alt="DomaiNesia Hills"></span>
            <span class="absolute" style="top:14%;"><img src="/sukun-new.svg?CVMIBLabs" alt="PT SUKUN Mc. WARTONO"></span>
            <span class="absolute animate-fadeandmove -left-10" style="top:9%;"><img src="//static.domainesia.com/assets/slots/webcdn/cpanel/img/landing/clouds.svg" alt="DomaiNesia Clouds"></span>
        </div>
    </div>
</template>

<script>
export default{
    mounted(){
        document.addEventListener("mousemove", parallax);
        function parallax(event) {
            this.querySelectorAll(".parallax .move").forEach((shift) => {
                const position = shift.getAttribute("value");
                const x = (window.innerWidth - event.pageX * position) / 80;
                const y = (window.innerHeight - event.pageY * position) / 80;

                shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
            });
        }
    }
}
</script>