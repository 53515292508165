import { createRouter, createMemoryHistory } from 'vue-router'
import store from '../store'

import HomeView from '../views/HomeView.vue'

import BlankView        from '../views/BlankView.vue'
import HeaderView       from '../views/HeaderView.vue'
import NavbarView       from '../views/NavbarView.vue'

import HeaderBiodataView from '../views/HeaderBiodataView.vue'
import NavbarBiodataView from '../views/NavbarBiodataView.vue'
import BiodataView      from '../views/BiodataView.vue'

import HeaderCFITView       from '../views/HeaderCFITView.vue'
import NavbarCFITINST1View  from '../views/NavbarCFITINST1View.vue'
import NavbarCFITINST2View  from '../views/NavbarCFITINST2View.vue'
import NavbarCFITINST3View  from '../views/NavbarCFITINST3View.vue'
import NavbarCFITINST4View  from '../views/NavbarCFITINST4View.vue'

import CFITINST1View       from '../views/CFITINST1View.vue'
import CFITINST2View       from '../views/CFITINST2View.vue'
import CFITINST3View       from '../views/CFITINST3View.vue'
import CFITINST4View       from '../views/CFITINST4View.vue'

import NavbarCFIT1View  from '../views/NavbarCFIT1View.vue'
import NavbarCFIT2View  from '../views/NavbarCFIT2View.vue'
import NavbarCFIT3View  from '../views/NavbarCFIT3View.vue'
import NavbarCFIT4View  from '../views/NavbarCFIT4View.vue'

import CFIT1View       from '../views/CFIT1View.vue'
import CFIT2View       from '../views/CFIT2View.vue'
import CFIT3View       from '../views/CFIT3View.vue'
import CFIT4View       from '../views/CFIT4View.vue'

import HeaderKraepelinView from '../views/HeaderKraepelinView.vue'
import NavbarKraepelinInstView from '../views/NavbarKraepelinInstView.vue'
import KraepelinInstView from '../views/KraepelinInstView.vue'

import NavbarKraepelinSim1View from '../views/NavbarKraepelinSim1View.vue'
import KraepelinSim1View from '../views/KraepelinSim1View.vue'

import NavbarKraepelinSim2View from '../views/NavbarKraepelinSim2View.vue'
import KraepelinSim2View from '../views/KraepelinSim2View.vue'

import Navbar1View      from '../views/Navbar1View.vue'
import Kraepelin1View   from '../views/Kraepelin1View.vue'

import Navbar2View      from '../views/Navbar2View.vue'
import Kraepelin2View   from '../views/Kraepelin2View.vue'

import Navbar3View      from '../views/Navbar3View.vue'
import Kraepelin3View   from '../views/Kraepelin3View.vue'

import Navbar4View      from '../views/Navbar4View.vue'
import Kraepelin4View   from '../views/Kraepelin4View.vue'

import Navbar5View      from '../views/Navbar5View.vue'
import Kraepelin5View   from '../views/Kraepelin5View.vue'

import Navbar6View      from '../views/Navbar6View.vue'
import Kraepelin6View   from '../views/Kraepelin6View.vue'

import Navbar7View      from '../views/Navbar7View.vue'
import Kraepelin7View   from '../views/Kraepelin7View.vue'

import Navbar8View      from '../views/Navbar8View.vue'
import Kraepelin8View   from '../views/Kraepelin8View.vue'

import Navbar9View      from '../views/Navbar9View.vue'
import Kraepelin9View   from '../views/Kraepelin9View.vue'

import Navbar10View      from '../views/Navbar10View.vue'
import Kraepelin10View   from '../views/Kraepelin10View.vue'

import Navbar11View      from '../views/Navbar11View.vue'
import Kraepelin11View   from '../views/Kraepelin11View.vue'

import Navbar12View      from '../views/Navbar12View.vue'
import Kraepelin12View   from '../views/Kraepelin12View.vue'

import Navbar13View      from '../views/Navbar13View.vue'
import Kraepelin13View   from '../views/Kraepelin13View.vue'

import Navbar14View      from '../views/Navbar14View.vue'
import Kraepelin14View   from '../views/Kraepelin14View.vue'

import Navbar15View      from '../views/Navbar15View.vue'
import Kraepelin15View   from '../views/Kraepelin15View.vue'

import Navbar16View      from '../views/Navbar16View.vue'
import Kraepelin16View   from '../views/Kraepelin16View.vue'

import Navbar17View      from '../views/Navbar17View.vue'
import Kraepelin17View   from '../views/Kraepelin17View.vue'

import Navbar18View      from '../views/Navbar18View.vue'
import Kraepelin18View   from '../views/Kraepelin18View.vue'

import Navbar19View      from '../views/Navbar19View.vue'
import Kraepelin19View   from '../views/Kraepelin19View.vue'

import Navbar20View      from '../views/Navbar20View.vue'
import Kraepelin20View   from '../views/Kraepelin20View.vue'

import Navbar21View      from '../views/Navbar21View.vue'
import Kraepelin21View   from '../views/Kraepelin21View.vue'

import Navbar22View      from '../views/Navbar22View.vue'
import Kraepelin22View   from '../views/Kraepelin22View.vue'

import Navbar23View      from '../views/Navbar23View.vue'
import Kraepelin23View   from '../views/Kraepelin23View.vue'

import Navbar24View      from '../views/Navbar24View.vue'
import Kraepelin24View   from '../views/Kraepelin24View.vue'

import Navbar25View      from '../views/Navbar25View.vue'
import Kraepelin25View   from '../views/Kraepelin25View.vue'

import Navbar26View      from '../views/Navbar26View.vue'
import Kraepelin26View   from '../views/Kraepelin26View.vue'

import Navbar27View      from '../views/Navbar27View.vue'
import Kraepelin27View   from '../views/Kraepelin27View.vue'

import Navbar28View      from '../views/Navbar28View.vue'
import Kraepelin28View   from '../views/Kraepelin28View.vue'

import Navbar29View      from '../views/Navbar29View.vue'
import Kraepelin29View   from '../views/Kraepelin29View.vue'

import Navbar30View      from '../views/Navbar30View.vue'
import Kraepelin30View   from '../views/Kraepelin30View.vue'

import Navbar31View      from '../views/Navbar31View.vue'
import Kraepelin31View   from '../views/Kraepelin31View.vue'

import Navbar32View      from '../views/Navbar32View.vue'
import Kraepelin32View   from '../views/Kraepelin32View.vue'

import Navbar33View      from '../views/Navbar33View.vue'
import Kraepelin33View   from '../views/Kraepelin33View.vue'

import Navbar34View      from '../views/Navbar34View.vue'
import Kraepelin34View   from '../views/Kraepelin34View.vue'

import Navbar35View      from '../views/Navbar35View.vue'
import Kraepelin35View   from '../views/Kraepelin35View.vue'

import Navbar36View      from '../views/Navbar36View.vue'
import Kraepelin36View   from '../views/Kraepelin36View.vue'

import Navbar37View      from '../views/Navbar37View.vue'
import Kraepelin37View   from '../views/Kraepelin37View.vue'

import Navbar38View      from '../views/Navbar38View.vue'
import Kraepelin38View   from '../views/Kraepelin38View.vue'

import Navbar39View      from '../views/Navbar39View.vue'
import Kraepelin39View   from '../views/Kraepelin39View.vue'

import Navbar40View      from '../views/Navbar40View.vue'
import Kraepelin40View   from '../views/Kraepelin40View.vue'

import Navbar41View      from '../views/Navbar41View.vue'
import Kraepelin41View   from '../views/Kraepelin41View.vue'

import Navbar42View      from '../views/Navbar42View.vue'
import Kraepelin42View   from '../views/Kraepelin42View.vue'

import Navbar43View      from '../views/Navbar43View.vue'
import Kraepelin43View   from '../views/Kraepelin43View.vue'

import Navbar44View      from '../views/Navbar44View.vue'
import Kraepelin44View   from '../views/Kraepelin44View.vue'

import Navbar45View      from '../views/Navbar45View.vue'
import Kraepelin45View   from '../views/Kraepelin45View.vue'

import Navbar46View      from '../views/Navbar46View.vue'
import Kraepelin46View   from '../views/Kraepelin46View.vue'

import Navbar47View      from '../views/Navbar47View.vue'
import Kraepelin47View   from '../views/Kraepelin47View.vue'

import Navbar48View      from '../views/Navbar48View.vue'
import Kraepelin48View   from '../views/Kraepelin48View.vue'

import Navbar49View      from '../views/Navbar49View.vue'
import Kraepelin49View   from '../views/Kraepelin49View.vue'

import Navbar50View      from '../views/Navbar50View.vue'
import Kraepelin50View   from '../views/Kraepelin50View.vue'

import HeaderPapiView  from '../views/HeaderPapiView.vue'
import PapiInstView  from '../views/PapiInstView.vue'
import PapiView  from '../views/PapiView.vue'

import HeaderEndView  from '../views/HeaderEndView.vue'
import EndView        from '../views/EndView.vue'

import CloseView      from '../views/CloseView.vue'

const routes = [
    {
        path: '/',
        name: 'biodata',
        beforeEnter : (to,from) => { if(store.getters.getPageBiodata) { console.log('SKIPPING BIODATA'); return {name : 'cfitinst1'}} },
        components:
        {
            header: HeaderBiodataView,
            nav: NavbarBiodataView,
            default: BiodataView
        }
    },
    {
        path: '/cfit-inst-1',
        name: 'cfitinst1',
        beforeEnter : (to,from) => { if(store.getters.getPageCfitInst1) { console.log('SKIPPING CFIT INST 1'); return {name : 'cfit1'}} },
        components:
        {
            header: HeaderCFITView,
            nav: NavbarCFITINST1View,
            default: CFITINST1View
        }
    },
    {
        path: '/cfit1',
        name: 'cfit1',
        beforeEnter : (to,from) => { if(store.getters.getPageCfitSoal1) { console.log('SKIPPING CFIT TEST 1'); return {name : 'cfitinst2'}} },
        components:
        {
            header: HeaderCFITView,
            nav: NavbarCFIT1View,
            default: CFIT1View
        }
    },
    {
        path: '/cfit-inst-2',
        name: 'cfitinst2',
        beforeEnter : (to,from) => { if(store.getters.getPageCfitInst2) { console.log('SKIPPING CFIT INST 2'); return {name : 'cfit2'}} },
        components:
        {
            header: HeaderCFITView,
            nav: NavbarCFITINST2View,
            default: CFITINST2View
        }
    },
    {
        path: '/cfit2',
        name: 'cfit2',
        beforeEnter : (to,from) => { if(store.getters.getPageCfitSoal2) { console.log('SKIPPING CFIT TEST 2'); return {name : 'cfitinst3'}} },
        components:
        {
            header: HeaderCFITView,
            nav: NavbarCFIT2View,
            default: CFIT2View
        }
    },
    {
        path: '/cfit-inst-3',
        name: 'cfitinst3',
        beforeEnter : (to,from) => { if(store.getters.getPageCfitInst3) { console.log('SKIPPING CFIT INST 3'); return {name : 'cfit3'}} },
        components:
        {
            header: HeaderCFITView,
            nav: NavbarCFITINST3View,
            default: CFITINST3View
        }
    },
    {
        path: '/cfit3',
        name: 'cfit3',
        beforeEnter : (to,from) => { if(store.getters.getPageCfitSoal3) { console.log('SKIPPING CFIT TEST 3'); return {name : 'cfitinst4'}} },
        components:
        {
            header: HeaderCFITView,
            nav: NavbarCFIT3View,
            default: CFIT3View
        }
    },
    {
        path: '/cfit-inst-4',
        name: 'cfitinst4',
        beforeEnter : (to,from) => { if(store.getters.getPageCfitInst4) { console.log('SKIPPING CFIT INST 4'); return {name : 'cfit4'}} },
        components:
        {
            header: HeaderCFITView,
            nav: NavbarCFITINST4View,
            default: CFITINST4View
        }
    },
    {
        path: '/cfit4',
        name: 'cfit4',
        beforeEnter : (to,from) => { if(store.getters.getPageCfitSoal4) { console.log('SKIPPING CFIT TEST 4'); return {name : 'kraepelininst'}} },
        components:
        {
            header: HeaderCFITView,
            nav: NavbarCFIT4View,
            default: CFIT4View
        }
    },
    {
        path: '/kraepelin-inst',
        name: 'kraepelininst',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelinInst) { console.log('SKIPPING KRAEPELIN INST'); return {name : 'kraepelinsim1'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: NavbarKraepelinInstView,
            default: KraepelinInstView
        }
    },
    {
        path: '/kraepelin-sim1',
        name: 'kraepelinsim1',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelinSim1) { console.log('SKIPPING KRAEPELIN SIMULASI 1'); return {name : 'kraepelinsim2'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: NavbarKraepelinSim1View,
            default: KraepelinSim1View
        }
    },
    {
        path: '/kraepelin-sim2',
        name: 'kraepelinsim2',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelinSim2) { console.log('SKIPPING KRAEPELIN SIMULASI 2'); return {name : 'kraepelin1'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: NavbarKraepelinSim2View,
            default: KraepelinSim2View
        }
    },
    {
        path: '/kraepelin1',
        name: 'kraepelin1',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin1) { console.log('SKIPPING KRAEPELIN 1'); return {name : 'kraepelin2'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar1View,
            default: Kraepelin1View
        }
    },
    {
        path: '/kraepelin2',
        name: 'kraepelin2',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin2) { console.log('SKIPPING KRAEPELIN 2'); return {name : 'kraepelin3'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar2View,
            default: Kraepelin2View
        }
    },
    {
        path: '/kraepelin3',
        name: 'kraepelin3',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin3) { console.log('SKIPPING KRAEPELIN 3'); return {name : 'kraepelin4'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar3View,
            default: Kraepelin3View
        }
    },
    {
        path: '/kraepelin4',
        name: 'kraepelin4',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin4) { console.log('SKIPPING KRAEPELIN 4'); return {name : 'kraepelin5'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar4View,
            default: Kraepelin4View
        }
    },
    {
        path: '/kraepelin5',
        name: 'kraepelin5',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin5) { console.log('SKIPPING KRAEPELIN 5'); return {name : 'kraepelin6'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar5View,
            default: Kraepelin5View
        }
    },
    {
        path: '/kraepelin6',
        name: 'kraepelin6',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin6) { console.log('SKIPPING KRAEPELIN 6'); return {name : 'kraepelin7'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar6View,
            default: Kraepelin6View
        }
    },
    {
        path: '/kraepelin7',
        name: 'kraepelin7',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin7) { console.log('SKIPPING KRAEPELIN 7'); return {name : 'kraepelin8'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar7View,
            default: Kraepelin7View
        }
    },
    {
        path: '/kraepelin8',
        name: 'kraepelin8',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin8) { console.log('SKIPPING KRAEPELIN 8'); return {name : 'kraepelin9'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar8View,
            default: Kraepelin8View
        }
    },
    {
        path: '/kraepelin9',
        name: 'kraepelin9',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin9) { console.log('SKIPPING KRAEPELIN 9'); return {name : 'kraepelin10'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar9View,
            default: Kraepelin9View
        }
    },
    {
        path: '/kraepelin10',
        name: 'kraepelin10',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin10) { console.log('SKIPPING KRAEPELIN 10'); return {name : 'kraepelin11'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar10View,
            default: Kraepelin10View
        }
    },
    {
        path: '/kraepelin11',
        name: 'kraepelin11',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin11) { console.log('SKIPPING KRAEPELIN 11'); return {name : 'kraepelin12'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar11View,
            default: Kraepelin11View
        }
    },
    {
        path: '/kraepelin12',
        name: 'kraepelin12',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin12) { console.log('SKIPPING KRAEPELIN 12'); return {name : 'kraepelin13'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar12View,
            default: Kraepelin12View
        }
    },
    {
        path: '/kraepelin13',
        name: 'kraepelin13',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin13) { console.log('SKIPPING KRAEPELIN 13'); return {name : 'kraepelin14'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar13View,
            default: Kraepelin13View
        }
    },
    {
        path: '/kraepelin14',
        name: 'kraepelin14',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin14) { console.log('SKIPPING KRAEPELIN 14'); return {name : 'kraepelin15'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar14View,
            default: Kraepelin14View
        }
    },
    {
        path: '/kraepelin15',
        name: 'kraepelin15',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin15) { console.log('SKIPPING KRAEPELIN 15'); return {name : 'kraepelin16'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar15View,
            default: Kraepelin15View
        }
    },
    {
        path: '/kraepelin16',
        name: 'kraepelin16',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin16) { console.log('SKIPPING KRAEPELIN 16'); return {name : 'kraepelin17'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar16View,
            default: Kraepelin16View
        }
    },
    {
        path: '/kraepelin17',
        name: 'kraepelin17',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin17) { console.log('SKIPPING KRAEPELIN 17'); return {name : 'kraepelin18'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar17View,
            default: Kraepelin17View
        }
    },
    {
        path: '/kraepelin18',
        name: 'kraepelin18',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin18) { console.log('SKIPPING KRAEPELIN 18'); return {name : 'kraepelin19'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar18View,
            default: Kraepelin18View
        }
    },
    {
        path: '/kraepelin19',
        name: 'kraepelin19',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin19) { console.log('SKIPPING KRAEPELIN 19'); return {name : 'kraepelin20'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar19View,
            default: Kraepelin19View
        }
    },
    {
        path: '/kraepelin20',
        name: 'kraepelin20',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin20) { console.log('SKIPPING KRAEPELIN 20'); return {name : 'kraepelin21'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar20View,
            default: Kraepelin20View
        }
    },
    {
        path: '/kraepelin21',
        name: 'kraepelin21',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin21) { console.log('SKIPPING KRAEPELIN 21'); return {name : 'kraepelin22'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar21View,
            default: Kraepelin21View
        }
    },
    {
        path: '/kraepelin22',
        name: 'kraepelin22',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin22) { console.log('SKIPPING KRAEPELIN 22'); return {name : 'kraepelin23'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar22View,
            default: Kraepelin22View
        }
    },
    {
        path: '/kraepelin23',
        name: 'kraepelin23',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin23) { console.log('SKIPPING KRAEPELIN 23'); return {name : 'kraepelin24'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar23View,
            default: Kraepelin23View
        }
    },
    {
        path: '/kraepelin24',
        name: 'kraepelin24',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin24) { console.log('SKIPPING KRAEPELIN 24'); return {name : 'kraepelin25'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar24View,
            default: Kraepelin24View
        }
    },
    {
        path: '/kraepelin25',
        name: 'kraepelin25',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin25) { console.log('SKIPPING KRAEPELIN 25'); return {name : 'kraepelin26'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar25View,
            default: Kraepelin25View
        }
    },
    {
        path: '/kraepelin26',
        name: 'kraepelin26',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin26) { console.log('SKIPPING KRAEPELIN 26'); return {name : 'kraepelin27'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar26View,
            default: Kraepelin26View
        }
    },
    {
        path: '/kraepelin27',
        name: 'kraepelin27',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin27) { console.log('SKIPPING KRAEPELIN 27'); return {name : 'kraepelin28'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar27View,
            default: Kraepelin27View
        }
    },
    {
        path: '/kraepelin28',
        name: 'kraepelin28',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin28) { console.log('SKIPPING KRAEPELIN 28'); return {name : 'kraepelin29'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar28View,
            default: Kraepelin28View
        }
    },
    {
        path: '/kraepelin29',
        name: 'kraepelin29',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin29) { console.log('SKIPPING KRAEPELIN 29'); return {name : 'kraepelin30'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar29View,
            default: Kraepelin29View
        }
    },
    {
        path: '/kraepelin30',
        name: 'kraepelin30',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin30) { console.log('SKIPPING KRAEPELIN 30'); return {name : 'kraepelin31'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar30View,
            default: Kraepelin30View
        }
    },
    {
        path: '/kraepelin31',
        name: 'kraepelin31',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin31) { console.log('SKIPPING KRAEPELIN 31'); return {name : 'kraepelin32'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar31View,
            default: Kraepelin31View
        }
    },
    {
        path: '/kraepelin32',
        name: 'kraepelin32',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin32) { console.log('SKIPPING KRAEPELIN 32'); return {name : 'kraepelin33'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar32View,
            default: Kraepelin32View
        }
    },
    {
        path: '/kraepelin33',
        name: 'kraepelin33',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin33) { console.log('SKIPPING KRAEPELIN 33'); return {name : 'kraepelin34'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar33View,
            default: Kraepelin33View
        }
    },
    {
        path: '/kraepelin34',
        name: 'kraepelin34',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin34) { console.log('SKIPPING KRAEPELIN 34'); return {name : 'kraepelin35'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar34View,
            default: Kraepelin34View
        }
    },
    {
        path: '/kraepelin35',
        name: 'kraepelin35',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin35) { console.log('SKIPPING KRAEPELIN 35'); return {name : 'kraepelin36'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar35View,
            default: Kraepelin35View
        }
    },
    {
        path: '/kraepelin36',
        name: 'kraepelin36',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin36) { console.log('SKIPPING KRAEPELIN 36'); return {name : 'kraepelin37'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar36View,
            default: Kraepelin36View
        }
    },
    {
        path: '/kraepelin37',
        name: 'kraepelin37',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin37) { console.log('SKIPPING KRAEPELIN 37'); return {name : 'kraepelin38'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar37View,
            default: Kraepelin37View
        }
    },
    {
        path: '/kraepelin38',
        name: 'kraepelin38',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin38) { console.log('SKIPPING KRAEPELIN 38'); return {name : 'kraepelin39'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar38View,
            default: Kraepelin38View
        }
    },
    {
        path: '/kraepelin39',
        name: 'kraepelin39',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin39) { console.log('SKIPPING KRAEPELIN 39'); return {name : 'kraepelin40'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar39View,
            default: Kraepelin39View
        }
    },
    {
        path: '/kraepelin40',
        name: 'kraepelin40',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin40) { console.log('SKIPPING KRAEPELIN 40'); return {name : 'kraepelin41'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar40View,
            default: Kraepelin40View
        }
    },
    {
        path: '/kraepelin41',
        name: 'kraepelin41',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin41) { console.log('SKIPPING KRAEPELIN 41'); return {name : 'kraepelin42'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar41View,
            default: Kraepelin41View
        }
    },
    {
        path: '/kraepelin42',
        name: 'kraepelin42',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin42) { console.log('SKIPPING KRAEPELIN 42'); return {name : 'kraepelin43'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar42View,
            default: Kraepelin42View
        }
    },
    {
        path: '/kraepelin43',
        name: 'kraepelin43',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin43) { console.log('SKIPPING KRAEPELIN 43'); return {name : 'kraepelin44'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar43View,
            default: Kraepelin43View
        }
    },
    {
        path: '/kraepelin44',
        name: 'kraepelin44',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin44) { console.log('SKIPPING KRAEPELIN 44'); return {name : 'kraepelin45'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar44View,
            default: Kraepelin44View
        }
    },
    {
        path: '/kraepelin45',
        name: 'kraepelin45',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin45) { console.log('SKIPPING KRAEPELIN 45'); return {name : 'kraepelin46'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar45View,
            default: Kraepelin45View
        }
    },
    {
        path: '/kraepelin46',
        name: 'kraepelin46',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin46) { console.log('SKIPPING KRAEPELIN 46'); return {name : 'kraepelin47'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar46View,
            default: Kraepelin46View
        }
    },
    {
        path: '/kraepelin47',
        name: 'kraepelin47',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin47) { console.log('SKIPPING KRAEPELIN 47'); return {name : 'kraepelin48'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar47View,
            default: Kraepelin47View
        }
    },
    {
        path: '/kraepelin48',
        name: 'kraepelin48',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin48) { console.log('SKIPPING KRAEPELIN 48'); return {name : 'kraepelin49'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar48View,
            default: Kraepelin48View
        }
    },
    {
        path: '/kraepelin49',
        name: 'kraepelin49',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin49) { console.log('SKIPPING KRAEPELIN 49'); return {name : 'kraepelin50'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar49View,
            default: Kraepelin49View
        }
    },
    {
        path: '/kraepelin50',
        name: 'kraepelin50',
        beforeEnter : (to,from) => { if(store.getters.getPageKraepelin50) { console.log('SKIPPING KRAEPELIN 50'); return {name : 'papi_kostick_inst'}} },
        components:
        {
            header: HeaderKraepelinView,
            nav: Navbar50View,
            default: Kraepelin50View
        }
    },
    {
        path: '/papi-kostick-inst',
        name: 'papi_kostick_inst',
        beforeEnter : (to,from) => { if(store.getters.getPagePapiInst) { console.log('SKIPPING PAPIKOSTICK INS'); return {name : 'papi_kostick'}} },
        components:
        {
            header: HeaderPapiView,
            nav: NavbarBiodataView,
            default: PapiInstView
        }
    },
    {
        path: '/papi-kostick',
        name: 'papi_kostick',
        beforeEnter : (to,from) => { if(store.getters.getPagePapiSoal) { console.log('SKIPPING PAPIKOSTICK'); return {name : 'end'}} },
        components:
        {
            header: HeaderPapiView,
            nav: NavbarBiodataView,
            default: PapiView
        }
    },
    {
        path: '/end',
        name: 'end',
        components:
        {
            header: HeaderEndView,
            nav: NavbarBiodataView,
            default: EndView
        }
    },
    {
        path: '/close',
        name: 'close',
        components:
        {
            default: CloseView
        }
    },
]

const router = createRouter({
    history: createMemoryHistory(),
    routes
})

export default router
