<template>
	<div style="background-color: #fff;">
		<div id="form" class="container">
			<main>
				<div class="row g-5" style="max-width:100%">
					<div class="col-md-7 col-lg-8">
						<h4 class="mb-3">INSTRUKSI TES 2</h4>
						<form @submit.prevent="onSubmit">
							<img src="/images/AdministrasiKraeplin.jpg" style="width: 100%">
							<button class="w-100 btn btn-primary btn-lg" type="submit">Kirim</button>
						</form>
					</div>
					<div class="col-md-5 col-lg-4">
						<div class="position-sticky" style="top: 35vh;">
							<div class="p-3 mb-4 bg-light rounded-3 text-center">
								<h4 class="mb-3">INSTRUKSI TES 2</h4>
								<div class="container-fluid py-3">
									<div class="card bg-danger text-white fw-bold" id="countdown61">
										<div class="values card-body"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	var { Timer } = require('easytimer.js');
	import Swal from 'sweetalert2'
	export default{
		data(){
			return {

			}
		},
		methods : {
			async onSubmit(){
				try {
					var response = await axios.get(this.$store.getters.getServerURL+'par/kraepelin-petunjuk/'+this.$store.getters.getLokasi)
					if(response.data.data){
						await axios.get(this.$store.getters.getServerURL+'page/kraepelin-petunjuk/'+this.$store.getters.getId)
						this.$store.dispatch('setPageKraepelinInst',true)
						this.$router.replace({ name: 'kraepelinsim1' })
					} else {
						Swal.fire({
							title: 'Peringatan',
							text: response.data.error_desc,
							icon: 'warning',
							confirmButtonText: 'OK'
						})
					}
				} catch(error) {
					console.error(error)
				}
			}
		},
		mounted(){
			console.log('WELCOME KRAEPELIN INST')
			var timer = new Timer();
			timer.start({countdown: true, startValues: {seconds: 180}});

			$('#countdown61 .values').html(timer.getTimeValues().toString());

			timer.addEventListener('secondsUpdated', (e)=>{
				$('#countdown61 .values').html(timer.getTimeValues().toString());
			});

			timer.addEventListener('targetAchieved', (e)=>{
				this.onSubmit()
			});
		},
		unmounted(){
			console.log('GOOD BYE KRAEPELIN INST')
		}
	}
</script>