<template>
	<div style="background-color: #fff;">
        <div class="container-fluid">
            <main>
                <div class="row g-5" style="max-width:100%">
                    <div class="col-md-7 col-lg-8">
                    	<div class="p-3 mb-4 bg-light rounded-3">
							<div class="container-fluid py-5">
								<img src="/images/CFIT-0001.png" style="width: 100%;">
							</div>
						</div>
                    </div>
                    <div class="col-md-5 col-lg-4">
                    	<div class="position-sticky" style="top: 2rem;">
	                    	<div class="p-3 mb-4 bg-light rounded-3">
								<div class="container-fluid py-3">
									<h1 class="display-6 fw-bold">TES 1 - SUBTES 1</h1>
									<div class="card bg-danger text-white fw-bold" id="countdown1">
									    <div class="values card-body"></div>
									</div>
									<form @submit.prevent="onSubmit">
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>1.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio11" value="a" v-model="cfit1">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio12" value="b" v-model="cfit1">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio13" value="c" v-model="cfit1">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio14" value="d" v-model="cfit1">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio15" value="e" v-model="cfit1">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio16" value="f" v-model="cfit1">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-white">
										<b>2.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio21" value="a" v-model="cfit2">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio22" value="b" v-model="cfit2">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio23" value="c" v-model="cfit2">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio24" value="d" v-model="cfit2">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio25" value="e" v-model="cfit2">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio26" value="f" v-model="cfit2">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<div class="col-md-12 col-lg-12 my-2 bg-warning">
										<b>3.</b>&nbsp;&nbsp;&nbsp;
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio31" value="a" v-model="cfit3">
											<label class="form-check-label" for="inlineRadio1">a</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio32" value="b" v-model="cfit3">
											<label class="form-check-label" for="inlineRadio2">b</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio33" value="c" v-model="cfit3">
											<label class="form-check-label" for="inlineRadio3">c</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio34" value="d" v-model="cfit3">
											<label class="form-check-label" for="inlineRadio4">d</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio35" value="e" v-model="cfit3">
											<label class="form-check-label" for="inlineRadio5">e</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio36" value="f" v-model="cfit3">
											<label class="form-check-label" for="inlineRadio6">f</label>
										</div>
									</div>
									<button class="w-100 btn btn-primary btn-lg" type="submit">Kirim</button>
									</form>
								</div>
							</div>
						</div>
                    </div>
                </div>
			</main>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	var { Timer } = require('easytimer.js');
	import Swal from 'sweetalert2'
	export default{
	    data(){
			return {
				cfit1 : 'c',
				cfit2 : 'e',
				cfit3 : 'e'
			}
		},
		methods : {
			async onSubmit(){
				try {
					var response = await axios.get(this.$store.getters.getServerURL+'par/cfit-petunjuk1/'+this.$store.getters.getLokasi)
					if(response.data.data){
						await axios.get(this.$store.getters.getServerURL+'page/cfit-petunjuk1/'+this.$store.getters.getId)
						this.$store.dispatch('setPageCfitInst1',true)
						this.$router.replace({ name: 'cfit1' })
					} else {
						Swal.fire({
							title: 'Peringatan',
							text: response.data.error_desc,
							icon: 'warning',
							confirmButtonText: 'OK'
						})
					}
				} catch(error) {
					console.error(error)
				}
			}
		},
		mounted(){
			console.log('WELCOME CFIT INST 1')
			var timer = new Timer();
			timer.start({countdown: true, startValues: {seconds: 180 }})
			$('#countdown1 .values').html(timer.getTimeValues().toString())

			timer.addEventListener('secondsUpdated', (e)=>{
			    $('#countdown1 .values').html(timer.getTimeValues().toString())
			})

			timer.addEventListener('targetAchieved', (e)=>{
			    this.onSubmit()
			});
		},
	    unmounted(){
	        console.log('GOOD BYE CFIT INST 1')
	    }
	}
</script>